// JsFromRoutes CacheKey 2b50f16969f217aa0ec9878448972f01
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  filterValues: definePathHelper('get', '/api/ads/filter_values'),
  deleteAds: definePathHelper('delete', '/api/ads/delete_ads'),
  favourite: definePathHelper('post', '/api/ads/:id/favourite'),
  unfavourite: definePathHelper('post', '/api/ads/:id/unfavourite'),
  list: definePathHelper('get', '/api/ads'),
  get: definePathHelper('get', '/api/ads/:id'),
  update: definePathHelper('patch', '/api/ads/:id'),
}
