const Trash = props => (
  <svg width={props.width || '14px'} height={props.height || '14px'} viewBox="0 0 14 14" fill="none">
    <path d="M12.25 3.48844C10.3075 3.29594 8.35333 3.19678 6.405 3.19678C5.25 3.19678 4.095 3.25511 2.94 3.37178L1.75 3.48844" stroke={props.color || '#D22355'} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.95825 2.89925L5.08659 2.13508C5.17992 1.58091 5.24992 1.16675 6.23575 1.16675H7.76409C8.74992 1.16675 8.82575 1.60425 8.91325 2.14091L9.04159 2.89925" stroke={props.color || '#D22355'} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.9958 5.33179L10.6167 11.206C10.5525 12.1218 10.5 12.8335 8.87248 12.8335H5.12748C3.49998 12.8335 3.44748 12.1218 3.38332 11.206L3.00415 5.33179" stroke={props.color || '#D22355'} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.02588 9.625H7.96838" stroke={props.color || '#D22355'} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.54175 7.29175H8.45841" stroke={props.color || '#D22355'} strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default Trash
