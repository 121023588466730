import { useRef, useState } from 'react'

const Carousel = props => {
  const [offset, setOffset] = useState(0)
  const containerRef = useRef()
  const listRef = useRef()

  const onLeftClick = (event) => {
    event.stopPropagation()

    const itemsNumber = Math.floor(containerRef.current.offsetWidth / props.itemWidth)
    const moveWidth = itemsNumber * props.itemWidth

    setOffset(prev => {
      const nextOffset = prev + moveWidth

      if (nextOffset > 0) {
        return 0
      }

      return nextOffset
    })
  }

  const onRightClick = (event) => {
    event.stopPropagation()
    
    const itemsNumber = Math.floor(containerRef.current.offsetWidth / props.itemWidth)
    const moveWidth = itemsNumber * props.itemWidth
    const maxMoveWidth = (listRef.current.scrollWidth - listRef.current.offsetWidth)

    setOffset(prev => {
      const nextOffset = prev - moveWidth

      if (Math.abs(prev) === maxMoveWidth) {
        return 0
      }

      if (Math.abs(nextOffset) > maxMoveWidth) {
        return -maxMoveWidth
      }

      return nextOffset
    })
  }

  return (
    <div ref={containerRef} className={`${props.containerPadding ? props.containerPadding : 'px-12' } flex overflow-x-hidden relative`}>
      {offset !== 0 &&
        <div className="z-10 absolute left-0 rotate-180 top-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-white p-4" onClick={onLeftClick}>
          <RightIcon />
        </div>
      }
      <div className="overflow-x-hidden">
        <div style={{ transform: `translateX(${offset}px)` }} className="flex transition-all duration-500" ref={listRef}>
          { props.children }
        </div>
      </div>
      <div className="z-10 absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-white p-4" onClick={onRightClick}>
        <RightIcon />
      </div>
    </div>
  )
}


const RightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#5693f5" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
  </svg>
)

export default Carousel
