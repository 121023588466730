const Drawer = ({ children, isOpen, setIsOpen }) => (
  <div
    className={`
      fixed overflow-hidden z-10 bg-primary/85 inset-0 transform ease-in-out
      ${isOpen ? 'translate-x-0' : 'delay-150 translate-x-full'}
    `}
  >
    <div
      className={`
        w-screen max-w-[500px] right-0 absolute bg-white h-full shadow-xl duration-250 ease-in-out transition-transform transform
        ${isOpen ? 'translate-x-0' : 'translate-x-full'}
      `}
    >
      <div className="relative w-screen max-w-[500px] flex flex-col space-y-6 overflow-y-scroll h-full">
        {children}
      </div>
    </div>
    <div
      className={`w-screen h-full transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0'}`}
      onClick={() => setIsOpen(false)}
    ></div>
  </div>
)

export default Drawer
