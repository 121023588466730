// JsFromRoutes CacheKey c7a6e416e79800dbe3490ff51a046480
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  advertisersList: definePathHelper('get', '/api/advertisers/advertisers_list'),
  list: definePathHelper('get', '/api/advertisers'),
  get: definePathHelper('get', '/api/advertisers/:id'),
}
