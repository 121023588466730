import { useState, useEffect } from 'react';
import api from '~/api';
import Products from '~/components/Products';
import BulkActions from '~/components/Research/BulkActions';
import AddToListing from '~/components/Research/AddToListing';
import NotificationManager from '~/services/NotificationManager'
import Modal from '~/components/layout/Modal'
import Button from '~/components/layout/Button'
import { useGetUserQuery } from '../../store/api/userApiSlice'

const AmazonProductList = ({ updateProduct, filters }) => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [sortBy, setSortBy] = useState(["monthly_revenue", "desc"]);
  const [page, setPage] = useState(1);
  const [isDeleteOpen, setDeleteIsOpen] = useState(false);
  const [user, setUser] = useState()
  const { data, status } = useGetUserQuery()
  useEffect(() => {
    if(status === "fulfilled") setUser(data?.user)
  }, [status])

  const getProducts = () => {
    api.apiProducts.list({ query: { page, sortBy, filters: filters } }).then(data => {
      setProducts(data.products)
      setPagination(data.meta)
    })
  }

  useEffect(() => {
    getProducts();
  }, [page, sortBy]);

  useEffect(() => {
    setPage(1);
    getProducts();
  }, [filters]);

  const onPageChange = e => {
    setPage(e.selected + 1);
  };

  const onSort = value => {
    setSortBy(value);
    setPage(1);
  };

  const onProductClick = id => {
    updateProduct(products.find(p => p.asin === id));
  };

  const onBulkCancel = () => {
    setSelectedProducts([]);
  };

  const onBulkSubmit = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
    setSelectedProducts([]);
  };

  const onSelectionChange = ids => {
    setSelectedProducts(ids);
  };

  const onSubmitToSwipe = swipeId => {
    api.apiSwipes.addProducts({
      data: {
        id: swipeId,
        product_type: 'AmazonProduct',
        product_ids: selectedProducts
      }
    }).then(() => {
      onModalClose();
      NotificationManager.success({ boldText: `${selectedProducts.length} product`, text: 'have been successfully added to swipe' });
    });
  };


  const onBulkDelete =  () => {
    setDeleteIsOpen(false)
    api.apiProducts.deleteProducts({
      data: {
        product_ids: selectedProducts
      }
    }).then(() => {
      setProducts(products.filter(p => !selectedProducts.includes(p.asin)))
      NotificationManager.success({ boldText: `${selectedProducts.length} product`, text: 'have been successfully deleted' });
      setSelectedProducts([])
    });
  };

  return (
    <>
      {products && products.length > 0 &&
        <div>
          <Products
            data={products}
            pagination={pagination}
            sortBy={sortBy}
            onPageChange={onPageChange}
            onSort={onSort}
            selectedIds={selectedProducts}
            onSelectionChange={onSelectionChange}
            onProductClick={onProductClick}
          />
        </div>
      }
      {
        isModalOpen
        ? <AddToListing selectedIds={selectedProducts} onSubmit={onSubmitToSwipe} onClose={onModalClose} />
        : <BulkActions
          isOpen={selectedProducts.length}
          selectedIds={selectedProducts}
          onCancel={onBulkCancel}
          onAddToSwipe={onBulkSubmit}
          onDelete={user?.admin && (() => setDeleteIsOpen(true))}
        />
      }
      <Modal isOpen={isDeleteOpen} onClose={() => setDeleteIsOpen(false)} headerText="Delete Products">
        <div className="flex flex-col">
          <div className="flex text-secondary">Are you sure you want to delete {selectedProducts.length} selected products?</div>
          <div className="flex flex-1 mt-6">
            <div className="flex flex-1">
              <Button full secondary onClick={() => setDeleteIsOpen(false)}>Cancel</Button>
            </div>
            <div className="flex flex-1 ml-6" onClick={onBulkDelete}>
              <Button red full>Delete</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AmazonProductList;
