// JsFromRoutes CacheKey 86c3c8f0170283c28de1996c31f78371
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  favourite: definePathHelper('post', '/api/trending/:id/favourite'),
  unfavourite: definePathHelper('post', '/api/trending/:id/unfavourite'),
  dailyTrends: definePathHelper('get', '/api/trending/daily_trends'),
  realtimeTrends: definePathHelper('get', '/api/trending/realtime_trends'),
  list: definePathHelper('get', '/api/trending'),
  get: definePathHelper('get', '/api/trending/:id'),
}
