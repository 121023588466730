// JsFromRoutes CacheKey f14a33a59a310d1b786c8bef58cc3a33
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  recent: definePathHelper('get', '/api/search/recent'),
  product: definePathHelper('get', '/api/search/product/:id'),
  searchAmazonProducts: definePathHelper('get', '/api/search/search_amazon_products/:id'),
  searchAliExpressProducts: definePathHelper('get', '/api/search/search_ali_express_products/:id'),
  searchTikTokPosts: definePathHelper('get', '/api/search/search_tik_tok_posts/:id'),
  categories: definePathHelper('get', '/api/search/categories'),
  hideProducts: definePathHelper('post', '/api/search/hide_products'),
  destroy: definePathHelper('delete', '/api/search/:id'),
  get: definePathHelper('get', '/api/search'),
}
