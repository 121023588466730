const SortTop = props => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" {...props}>
    <g clipPath="url(#clip0_152_14679)">
      <path d="M7.61908 8.66112C7.53255 8.57451 7.42998 8.53125 7.31147 8.53125H1.18656C1.06799 8.53125 0.965491 8.57451 0.87889 8.66112C0.792288 8.74781 0.749023 8.85031 0.749023 8.96881C0.749023 9.08728 0.792288 9.18978 0.87889 9.2764L3.94136 12.3389C4.02805 12.4255 4.13055 12.4688 4.24902 12.4688C4.36749 12.4688 4.47009 12.4255 4.55662 12.3389L7.61908 9.27638C7.70559 9.18978 7.74902 9.08728 7.74902 8.96878C7.74902 8.85031 7.70559 8.74781 7.61908 8.66112Z" fill="#5693F5" />
    </g>
    <g clipPath="url(#clip1_152_14679)">
      <path d="M0.878962 5.33888C0.965492 5.42549 1.06806 5.46875 1.18658 5.46875L7.31149 5.46875C7.43006 5.46875 7.53256 5.42549 7.61916 5.33888C7.70576 5.25219 7.74902 5.14969 7.74902 5.03119C7.74902 4.91272 7.70576 4.81022 7.61916 4.7236L4.55669 1.66113C4.46999 1.57453 4.36749 1.53117 4.24902 1.53117C4.13055 1.53117 4.02796 1.57453 3.94143 1.66113L0.878962 4.72362C0.792457 4.81022 0.749024 4.91272 0.749024 5.03122C0.749024 5.14969 0.792457 5.25219 0.878962 5.33888Z" fill="#5693F5" />
    </g>
    <defs>
      <clipPath id="clip0_152_14679">
        <rect width="7" height="7" fill="white" transform="translate(0.749023 7)" />
      </clipPath>
      <clipPath id="clip1_152_14679">
        <rect width="7" height="7" fill="white" transform="translate(7.74902 7) rotate(180)" />
      </clipPath>
    </defs>
  </svg>
)

export default SortTop
