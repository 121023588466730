import React, { useEffect, useRef } from 'react'

const Checkbox = props => {
  const node = useRef()
  const classNames = ['checkbox']
  // if (props.secondary) classNames.push('secondary')

  useEffect(() => {
    node.current.indeterminate = props.indeterminate
  }, [props.indeterminate])

  return (
    <input
      id={props.id}
      ref={node}
      type="checkbox"
      checked={props.checked}
      onClick={props.onClick}
      onChange={props.onChange}
      className={classNames.join(' ')}
    />
  )
}

export default React.memo(Checkbox)
