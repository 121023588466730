import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { nFormatter } from '~/helpers/formatter'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import MoreIcon from '~/components/icons/More'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Drawer from '~/components/layout/Drawer'
import Filters from '~/components/Research/Filters'
import Button from '~/components/layout/Button'
import AmazonProductList from './AmazonProductList'
import DrawerProduct from './DrawerProduct'
import { Tooltip } from 'react-tooltip'

dayjs.extend(relativeTime)

export const formatCurrency = (num) => {
  const symbols = ['', 'K', 'M', 'B', 'T'];
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  let currencyValue = parseFloat(num.toString().replace(/[^0-9.-]+/g, ''));
  let index = 0;
  while (currencyValue >= 1000 && index < symbols.length - 1) {
    currencyValue /= 1000;
    index++;
  }
  return '$' + currencyValue.toFixed(2).replace(regex, ',') + symbols[index];
};

export const SearchBox = props => {
  const navigate = useNavigate()
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false)
  const btnDropdownRef = useRef()
  const popoverDropdownRef = useRef()

  const openDropdownPopover = () => {
    setDropdownPopoverShow(true)
  }
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false)
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (popoverDropdownRef.current && !popoverDropdownRef.current.contains(event.target) && !btnDropdownRef.current.contains(event.target)) {
        closeDropdownPopover()
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const onClick = () => {
    navigate(`/research/${props.search.query}`)
  }

  const onDelete = e => {
    e.stopPropagation()
    props.onDelete(props.search.id)
  }

  const onMoreClick = e => {
    e.stopPropagation()
    dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover()
  }

  return (
    <div className="min-w-[24%] max-w-[24%] p-4 drop-shadow-[0_4px_10px_5px_rgba(0,0,0,0.05)] rounded-[10px] bg-white cursor-pointer flex flex-col justify-between" onClick={onClick}>
      <div className="px-2 relative">
        <div className="font-bold text-[22px] truncate pr-4" data-tooltip-id="query-tooltip" data-tooltip-content={props.search.query}>{ props.search.query }</div>
        <div className="absolute right-0 top-0" ref={btnDropdownRef} onClick={onMoreClick}><MoreIcon /></div>
        <div
          ref={popoverDropdownRef}
          className={`${dropdownPopoverShow ? 'block' : 'hidden '} min-w-fit text-primary absolute top-6 right-0 bg-white text-base float-left list-none text-left rounded shadow-lg`}
        >
          <div
            className="text-sm py-2 px-6 block w-full whitespace-nowrap bg-transparent hover:bg-blue1/30"
            onClick={onDelete}
          >
            Delete
          </div>
        </div>
      <Tooltip id="query-tooltip" variant='dark' place='top' positionStrategy='fixed' style={{ maxWidth: 300, fontSize: 12 }} />

      </div>
      <div className="flex mt-4 gap-2 justify-between">
        <div className="flex-col py-0.5">
          <div className="text-sm text-center">{ props.search.searchVolume ? nFormatter(props.search.searchVolume) : 'N/A' }</div>
          <div className="text-[8px] mt-1.5 text-center uppercase">Avg. Search Volume per/mo</div>
        </div>
        <div className="flex-col py-0.5">
          <div className="text-sm text-center">{ nFormatter(props.search.averageMonthlySales) }</div>
          <div className="text-[8px] mt-1.5 text-center uppercase">Avg. Sales per/mo</div>
        </div>
        <div className="flex-col py-0.5">
          <div className="text-sm text-center">{ formatCurrency(props.search.averageMonthlyRevenue/100) }</div>
          <div className="text-[8px] mt-1.5 text-center uppercase">Avg. Revenue per/mo</div>
        </div>
      </div>

    </div>
  )
}

const RecentSearch = () => {
  const [searches, setSearches] = useState([])
  const [product, setProduct] = useState(null)
  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const [filters, setFilters] = useState(null)

  useEffect(() => {
    api.apiSearch.recent().then(data => {
      setSearches(data.searches)
    })
  }, [])

  const onDelete = id => {
    if (window.confirm('Are you sure you want to delete this search')) { // eslint-disable-line no-alert
      api.apiSearch.destroy({ id })
      .then(data => {
        setSearches(prev => prev.filter(s => s.id !== id))
        NotificationManager.success({ boldText: data.search.query, text: 'have been successfully deleted' })
      })
    }
  }

  const showFilters = () => {
    setFiltersOpen(true)
  }

  const onFiltersChange = value => {
    setFilters(value)
    setFiltersOpen(false)
  }

  const onFiltersCancel = () => {
    setFilters(null)
    setFiltersOpen(false)
  }

  const count = searches.length
  const firstRow = count > 8 ? searches.slice(0, Math.ceil(count/2)) : searches.slice(0, 4)
  const secondRow = count > 8 ? searches.slice(Math.ceil(count/2)) : searches.slice(4)

  return (
    <div className="flex flex-col overflow-scroll w-full">
      {!!searches.length &&
        <div className="mt-12 w-full">
          <div className="text-8">Recent search</div>
          <div className="w-full overflow-x-scroll">
            <div className="flex gap-4 mt-4">
              {
                firstRow.map(search => (
                  <SearchBox key={search.id} id={search.id} search={search} onDelete={onDelete} />
                ))
              }
            </div>
            <div className="flex gap-4 mt-4">
              {
                secondRow.map(search => (
                  <SearchBox key={search.id} id={search.id} search={search} onDelete={onDelete} />
                ))
              }
            </div>
          </div>
        </div>
      }
      <Drawer isOpen={!!product} setIsOpen={()=>setProduct(null)}>
        { product && <DrawerProduct product={product} resetProduct={() => setProduct(null)} /> }
      </Drawer>
      <Drawer isOpen={isFiltersOpen} setIsOpen={setFiltersOpen}>
        <Filters filters={filters} onChange={onFiltersChange} onCancel={onFiltersCancel} onClose={() => setFiltersOpen(false)} hideCategoryFilter={true} />
      </Drawer>
      <div className="mt-12 w-full">
        <div className="w-auto flex flex-1 justify-between items-center">
          <div className="text-8">Amazon Products</div>
          <div className="flex items-center">
            {filters && <div className="mr-4 text-[#5A94F1] cursor-pointer" onClick={() => setFilters(null)}>(Clear Filters )</div>}
            <div className="mr-4"><Button secondary noShadow onClick={showFilters}>Filter By</Button></div>
          </div>
        </div>
        <AmazonProductList displayProduct={!!product} updateProduct={setProduct} filters={filters} />
      </div>
    </div>
  )
}

export default RecentSearch
