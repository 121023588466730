import React, { useRef, useCallback } from 'react'
import { nFormatter } from '~/helpers/formatter'
import { Tooltip } from 'react-tooltip'
import ProgressiveImage from 'react-progressive-graceful-image'
import TrendingFavoriteIcon from '~/components/icons/TrendingFavorite'
import CalendarIcon from '~/components/icons/Calendar'
import lookup from 'country-code-lookup'
import { useSearchParams } from 'react-router-dom'
import ResearchProduct from '~/pages/ResearchProduct'
import TikTokPost from '../ResearchProduct/TikTokPost'
const newVideoHeight = 300

const getCountry = code => {
  if (!code) return ''

  return lookup.byIso(code)?.country || 'ALL'
}

function TikTokPostSearch({posts, onPostSelect}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const postId = searchParams.get('postId')
  const post = posts.find(p => p.awemeId === postId)
  const onPostClick = useCallback(id => {
    setSearchParams({ postId: id })
  }, [])

  return (
    <>
      <div className="relative w-full overflow-x-auto mt-7">
        <div className="w-full grid -mt-1 lg:gap-6 xl:grid-cols-4 md:gap-4 lg:grid-cols-3 gap-2 grid-cols-2">
          {
            posts.map(post => {
              return (
                <PostCard post={post} key={post.awemeId} onClick={onPostClick} onAddToSwipe={onPostSelect}/>
              )
            })
          }
        </div>
      </div>
      { post && <ResearchProduct><TikTokPost post={post} onAddToSwipe={onPostSelect} /></ResearchProduct> }
    </>
  )
}

export const PostCard = ({post, onClick, onAddToSwipe}) => {
  const videoRef = useRef()

  const onVideoMouseEnter = () => {
    videoRef.current.play()
  }

  const onVideoMouseLeave = () => {
    videoRef.current.pause()
  }

  const onVideoClick = e => {
    e.preventDefault()
    e.stopPropagation()
    e.target.muted = !e.target.muted
  }

  const getVideoWidth = () => {
    if (!post.size?.length) return 170

    const [videoWidth, videoHeight] = post.size.split('/')
    return videoWidth * newVideoHeight / videoHeight
  }

  const handleAddToSwipe = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onAddToSwipe(post.id);
  }

  return (
    <div className="relative flex flex-col w-full mt-6 xl:p-5 p-3 cursor-pointer rounded-lg bg-white shadow-[0_4px_50px_rgba(0,0,0,0.05)]" onClick={() => onClick(post.awemeId)}>
          <div className="flex">
            <div className="flex w-full">
              <div className="rounded-full w-[34px] h-[30px] relative flex-shrink-0">
                <img className="rounded-full h-[30px] w-[30px] bg-gray" src={post.author?.logoUrl} />
              </div>
              <div className="flex flex-col ml-2 w-full">
                <div className="flex items-start justify-between">
                  <div className="text-xs font-medium leading-[13px] break-all">
                    { post.author?.name }
                  </div>
                  {!!post.isAd && <div className="ml-2 bg-gray1 rounded-[10px] py-1 px-2 text-[10px] leading-[11px] whitespace-nowrap">Sponsored</div> }
                </div>
                <div className="mt-1 text-[10px] leading-[11px] text-secondary">{ getCountry(post.countryCode) }</div>
              </div>
            </div>
            {typeof onAddToSwipe === 'function' &&
              <div className="flex-0 text-gray ml-2" onClick={handleAddToSwipe}>
                <TrendingFavoriteIcon $active={post.swipesIds?.length > 0} />
              </div>
            }
          </div>
          <div onMouseEnter={onVideoMouseEnter} onMouseLeave={onVideoMouseLeave} className="flex justify-center mt-4 relative">
            <ProgressiveImage src={post.coverUrl} placeholder="">
              {(src, loading) => (
                loading
                ? <div className="rounded-lg w-full min-w-full h-[300px] bg-gray opacity-10" />
                : <img src={src} className="rounded-lg w-full min-w-full h-[300px] opacity-25" />
              )}
            </ProgressiveImage>
            <video onClick={onVideoClick} muted loop ref={videoRef} width={getVideoWidth()} height={newVideoHeight} className="absolute top-0 left-1/2 -translate-x-1/2 h-[300px]" controls poster={post.coverUrl} preload="none">
              <source src={post.videoUrl} type="video/mp4" />
            </video>
          </div>

          <div className="flex flex-col my-4">
            <div className="flex items-center">
              <div className="flex-auto text-[20px] leading-8 truncate min-h-[30px]" data-tooltip-id="title-tooltip" data-tooltip-content={post.title}>
                { post.title }
              </div>
              <div className="flex-0 w-[80px] max-w-[80px]">

              </div>
            </div>
            <div className="mt-2 text-[10px] flex items-center">
              <CalendarIcon />
              <span className="ml-1">{ post.firstSeen } - { post.lastSeen }</span>
            </div>
            <div className="mt-2 text-[10px] flex items-center">
            </div>
          </div>
          <div className="flex flex-0 w-full py-[11px] border-t border-gray2 justify-between items-center font-medium">
            <div className="flex-1 flex flex-col items-center">
              <div className="">{ nFormatter(post.impressions) }</div>
              <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Post Impressions</div>
            </div>
            <div className="flex-1 flex flex-col items-center">
              <div className="">{ post.seenDiff }</div>
              <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Days</div>
            </div>
            <div className="flex-1 flex flex-col items-center">
              <div className="">{ nFormatter(post.popularity) }</div>
              <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Popularity</div>
            </div>
          </div>
          <Tooltip id="title-tooltip" variant='dark' place='top' positionStrategy='fixed' style={{ maxWidth: 300, fontSize: 12 }} />
        </div>
  )
}
export default TikTokPostSearch
