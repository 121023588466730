import { Link } from 'react-router-dom'
import ProgressiveImage from 'react-progressive-graceful-image'
import { Tooltip } from 'react-tooltip'
import { nFormatter, toPercentage, currencyFormatter } from '~/helpers/formatter'
import TrendingFavoriteIcon from '~/components/icons/TrendingFavorite'
import CalendarIcon from '~/components/icons/Calendar'

const AdProductCard = props => {
  const { product } = props
  const [firstImage, bgImage] = product.images

  const addToSwipe = e => {
    e.stopPropagation()
    e.preventDefault()

    props.addToSwipe(product.id)
  }

  const linkTo = typeof props.addToSwipe === 'function' ? `/products/${product.id}` : '#'

  return (
    <Link className="relative flex flex-col w-full mt-6 cursor-pointer rounded-lg bg-white shadow-[0_4px_50px_rgba(0,0,0,0.05)]" to={linkTo}>
      <div className="flex justify-center relative">
        <ProgressiveImage src={firstImage} placeholder="">
          {(src, loading) => (
            loading
            ? <div className="rounded-lg w-full min-w-full h-[300px] bg-gray opacity-10" />
            : <div className="rounded-lg w-full relative h-[300px]">
              { !!bgImage?.length && <img src={bgImage} className="rounded-lg w-full min-w-full h-[300px] opacity-25" /> }
              <img src={src} className="rounded-lg absolute top-0 left-1/2 -translate-x-1/2 h-[300px]" />
            </div>
          )}
        </ProgressiveImage>
      </div>
      <div className="flex flex-col p-4">
        <div className="flex items-center">
          <div className="flex-auto text-[20px] leading-8 truncate min-h-[30px]" data-tooltip-id="title-tooltip" data-tooltip-content={product.title}>
            { product.title }
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex-auto leading-8 truncate min-h-[30px] text-orange-500 font-medium">
            { currencyFormatter(product.price, product.currencyCode) }
          </div>
          {typeof props.addToSwipe === 'function' &&
            <div className="flex-0 text-gray ml-2" onClick={addToSwipe}>
              <TrendingFavoriteIcon $active={props.isFavourite} />
            </div>
          }
        </div>
        <div className="mt-2 flex">
          <div className="flex-1 flex flex-col items-center justify-between">
            <div className="h-4 mb-1 text-[10px] text-gray">First Ad Date</div>
            <CalendarIcon width="22px" height="22px" />
            <span className="mt-1 text-xs">{ product.firstSeen }</span>
          </div>
          <div className="flex-1 flex flex-col items-center justify-between">
            <div className="h-4 mb-1 text-[10px] text-gray"></div>
            <span className="text">{ product.adsCount }</span>
            <span className="mt-1 text-xs">Ads</span>
          </div>
          <div className="flex-1 flex flex-col items-center justify-between">
            <div className="h-4 mb-1 text-[10px] text-gray">Last Ad Date</div>
            <CalendarIcon width="22px" height="22px" />
            <span className="mt-1 text-xs">{ product.lastSeen }</span>
          </div>
        </div>
      </div>
      <div className="flex flex-0 w-full py-[11px] px-4 border-t border-gray2 justify-between items-center font-medium">
        <div className="flex-1 flex flex-col items-center">
          <div className="">{ nFormatter(product.impressions) }</div>
          <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Impressions</div>
        </div>
        <div className="flex-1 flex flex-col items-center">
          <div className="">{ nFormatter(product.likes) }</div>
          <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Likes</div>
        </div>
        <div className="flex-1 flex flex-col items-center">
          <div className="">{ toPercentage(product.likes, product.impressions) }%</div>
          <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Like Rate</div>
        </div>
      </div>
      <Tooltip id="title-tooltip" variant='dark' place='top' positionStrategy='fixed' style={{ maxWidth: 300, fontSize: 12 }} />
    </Link>
  )
}

export default AdProductCard
