import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import Product from '~/components/Product'
import Checkbox from '~/components/layout/Checkbox'
import SortTopIcon from '~/components/icons/SortTop'

const SortColumn = props => (
  <div className="flex-1 flex items-center justify-center" onClick={props.onSort}>
    <div className="flex cursor-pointer select-none p-1">
      <span>{ props.title }</span>
      <div className="ml-1"><SortTopIcon /></div>
    </div>
  </div>
)

const Products = props => {
  const paginatedProducts = props.data

  const toggleProductSelect = id => {
    const newSelectionIds = props.selectedIds.includes(id) ? props.selectedIds.filter(el => el !== id) : [...props.selectedIds, id]
    props.onSelectionChange?.(newSelectionIds)
  }

  const paginatedProductsLength = paginatedProducts.length
  const currentPageSelected = props.selectedIds.filter(asin => paginatedProducts.find(p => p.asin === asin))
  const currentPageSelectedLength = currentPageSelected.length

  const isChecked = (() => {
    if (currentPageSelected.length < paginatedProductsLength) return false
    if (!currentPageSelected.length) return false

    return currentPageSelected.every(asin => paginatedProducts.find(p => p.asin === asin))
  })()

  const isIndeterminate = !isChecked && currentPageSelectedLength > 0 && currentPageSelectedLength < paginatedProductsLength

  const toggleAll = () => {
    if (isIndeterminate) {
      const newIds = props.selectedIds.filter(asin => !paginatedProducts.find(p => p.asin === asin))
      props.onSelectionChange?.(newIds)
    } else {
      props.onSelectionChange?.([...props.selectedIds, ...paginatedProducts.map(p => p.asin)])
    }
  }

  const onSort = column => {
    props.onSort([column, props.sortBy[0] === column ? (props.sortBy[1] === 'asc' ? 'desc' : 'asc') : 'desc'])
  }

  return (
    <div className="mt-7">
      <div className="flex flex-col">
        <div className="flex min-h-[2.5rem] bg-white text-gray text-[10px] uppercase tracking-wider rounded-t-lg">
          <div className="flex basis-[50px] items-center justify-center">
            <div className="flex items-center">
              <Checkbox checked={isChecked} onChange={toggleAll} indeterminate={isIndeterminate} />
            </div>
          </div>
          <div className="flex items-center 2xl:basis-[320px] xl:basis-[280px] basis-[240px]">Product</div>
          <SortColumn title="Price" onSort={onSort.bind(this, 'price')} />
          <SortColumn title="EST. Sales P/M (Last 30 days)" onSort={onSort.bind(this, 'monthly_sales')} />
          <SortColumn title="Revenue P/M (Last 30 days)" onSort={onSort.bind(this, 'monthly_revenue')} />
          <SortColumn title="BSR" onSort={onSort.bind(this, 'bestseller_rank')} />
          <SortColumn title="Gross Profit" onSort={onSort.bind(this, 'price')} />
          <SortColumn title="Seller Type" onSort={null}/>
          <SortColumn title="Reviews and ratings" onSort={onSort.bind(this, 'review_rating')} />
          { typeof props.onAdd === 'function' && <div className="flex basis-[100px] items-center justify-center"></div> }
        </div>
        {
          paginatedProducts.map(product => (
            <Product
              key={product.asin}
              product={product}
              onAdd={props.onAdd}
              checked={props.selectedIds.includes(product.asin)}
              toggleSelect={toggleProductSelect}
              onClick={props.onProductClick}
            />
          ))
        }
      </div>
      {props.pagination && props.pagination.pages > 1 &&
        <ReactPaginate
          onPageChange={props.onPageChange}
          pageRangeDisplayed={5}
          pageCount={props.pagination.pages}
          renderOnZeroPageCount={null}
          className="pagination justify-end mt-4"
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={props.pagination.page - 1}
        />
      }
    </div>
  )
}
Products.defaultProps = {
  filters: {}
}

export default Products
