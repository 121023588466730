import { useState } from 'react'

const MAIN_IMAGE = 'MAIN'

const Gallery = props => {
  const defaultMainImage = props.images.find(i => i.variant === MAIN_IMAGE) || props.images[0]
  const [mainImage, setMainImage] = useState(defaultMainImage)
  
  const onImageClick = img => {
    setMainImage(img)
  }

  return (
    <div className="flex flex-wrap w-full">
      <div className="flex flex-col items-center justify-center w-full rounded-lg overflow-hidden max-h-[500px]">
        <img className="object-contain h-[207px]" src={typeof mainImage === 'string' ? mainImage: mainImage.link} />
      </div>
      <div className="w-full grid gap-2 grid-cols-4 mt-2">
        {
          props.images.map((image,index) => (
            <div key={image.variant || index} className="flex flex-col items-center justify-center w-full rounded-lg overflow-hidden hover:scale-[1.03] transition-transform cursor-pointer" onClick={onImageClick.bind(this, image)}>
              <img className="object-contain w-[111px] h-[76px]" src={typeof image === 'string' ? image: image.link} />
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Gallery
