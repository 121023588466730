import { useRef, useEffect } from 'react'
import Button from '~/components/layout/Button'

const BulkActions = props => {
  const modalNode = useRef()

  useEffect(() => {
    if (props.selectedIds.length) {
      modalNode.current.classList.remove('opacity-0')
      modalNode.current.classList.remove('pointer-events-none')
    } else {
      modalNode.current.classList.add('opacity-0')
      modalNode.current.classList.add('pointer-events-none')
    }
  }, [props.selectedIds])

  return (
    <div ref={modalNode} className="transition-opacity opacity-0 pointer-events-none fixed bottom-7 left-[50%] translate-x-[-50%] flex items-center justify-center drop-shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
      <div className="modal-container bg-white rounded-[10px] shadow-[0_4px_50px_rgba(0,0,0,0.05)] z-50 overflow-y-auto w-[720px]">
        <div className="modal-content py-5 px-4 text-left">
          <div className="flex justify-between">
            <div className="flex">
              { props.onHide && <Button secondary noShadow onClick={props.onHide}>Hide Products</Button> }
              { props.onDelete && <Button secondary noShadow red onClick={props.onDelete}>Delete Products</Button> }
            </div>
            <div className="flex">
              <div className="flex"><Button secondary noShadow onClick={props.onCancel}>Cancel</Button></div>
              <div className="flex ml-4"><Button noShadow onClick={props.onAddToSwipe}>Add Into Swipe</Button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BulkActions
