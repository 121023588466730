import React, { useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import ProgressiveImage from 'react-progressive-graceful-image'
import { nFormatter } from '~/helpers/formatter'
import lookup from 'country-code-lookup'
import ArrowLeft from '~/components/icons/ArrowLeft'
import FavoriteIcon from '~/components/icons/FavoriteIcon'
import ExternalLinkIcon from '~/components/icons/ExternalLinkIcon'

const newVideoHeight = 400

dayjs.extend(relativeTime)

const getCountry = code => {
  if (!code) return ''

  return lookup.byIso(code)?.country || 'ALL'
}

const TikTokPost = React.memo(props => {
  const videoRef = useRef()

  // const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { id, title, createdAt, coverUrl, videoUrl, likes, shares, comments, downloads, popularity, impressions, size, author, videoId, firstSeen, lastSeen, countryCode } = props.post

  console.log(props)

  const url = `https://www.tiktok.com/@${author.uniqueId}/video/${videoId}`
  const onBack = () => {
    navigate(location.pathname)
  }

  const onVideoMouseEnter = () => {
    videoRef.current.play()
  }

  const onVideoMouseLeave = () => {
    videoRef.current.pause()
  }

  const onVideoClick = e => {
    e.preventDefault()
    e.stopPropagation()
    e.target.muted = !e.target.muted
  }

  const getVideoWidth = () => {
    if (!size?.length) return 170

    const [videoWidth, videoHeight] = size.split('/')
    return videoWidth * newVideoHeight / videoHeight
  }

  return (
    <div className="flex flex-col h-full py-5">
      <div className="flex items-center px-4 border-b border-gray2 pb-5">
        <div className="mr-4 cursor-pointer" onClick={onBack}><ArrowLeft /></div>
        <div className="flex flex-auto flex-col">
          <div className="flex text-xl leading-6">{ title }</div>
          <div className="flex justify-end mt-2.5">
            <div className="flex text-xs text-secondary">
              Added { dayjs(createdAt).fromNow() }
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col w-full px-20">
        <div onMouseEnter={onVideoMouseEnter} onMouseLeave={onVideoMouseLeave} className="flex justify-center mt-4 relative">
            <ProgressiveImage src={coverUrl} placeholder="">
              {(src, loading) => (
                loading
                ? <div className="rounded-lg w-full min-w-full h-[400px] bg-gray opacity-10" />
                : <img src={src} className="rounded-lg w-full min-w-full h-[400px] opacity-25" />
              )}
            </ProgressiveImage>
            <video onClick={onVideoClick} muted loop ref={videoRef} width={getVideoWidth()} height={newVideoHeight} className="absolute top-0 left-1/2 -translate-x-1/2 h-[400px]" controls poster={coverUrl} preload="none">
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
      </div>
      <div className="flex mt-5 px-4">
        <div className="flex flex-1 py-4 bg-white rounded-lg shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
          <div className="flex flex-col flex-1 px-4 border-r border-[rgba(159,166,183,0.2)]">
            <div className="text-[10px] uppercase font-medium text-gray">
              Likes
            </div>
            <div className="mt-2 text-lg font-medium leading-5">
              { nFormatter(likes) }
            </div>
          </div>
          <div className="flex flex-col flex-1 px-4 border-r border-[rgba(159,166,183,0.2)]">
            <div className="text-[10px] uppercase font-medium text-gray">
              Shares
            </div>
            <div className="mt-2 text-lg font-medium leading-5">
            { nFormatter(shares) }
            </div>
          </div>
          <div className="flex flex-1 px-4 items-center">
            <div className="flex flex-1 flex-col">
              <div className="text-[10px] uppercase font-medium text-gray">
                Comments
              </div>
              <div className="mt-2 text-lg font-medium leading-5">
              { nFormatter(comments) }
              </div>
            </div>
          </div>
          <div className="flex flex-1 px-4 items-center">
            <div className="flex flex-1 flex-col">
              <div className="text-[10px] uppercase font-medium text-gray">
                Downloads
              </div>
              <div className="mt-2 text-lg font-medium leading-5">
              { downloads }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-[30px] mx-4 rounded-lg border border-gray/20 text-sm">
        <div className="flex-auto flex border-b border-b-gray/20">
          <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
            Author Name
          </div>
          <div className="flex-auto p-6 break-all">
            { author?.name }
          </div>
        </div>
        <div className="flex-auto flex border-b border-b-gray/20">
          <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
            Country/Region
          </div>
          <div className="flex-auto p-6 break-all">
            { getCountry(countryCode) }
          </div>
        </div>
        <div className="flex-auto flex border-b border-b-gray/20">
          <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
            Popularity
          </div>
          <div className="flex-auto p-6 break-all">
            { nFormatter(popularity) }
          </div>
        </div>
        <div className="flex-auto flex border-b border-b-gray/20">
          <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
            Impressions
          </div>
          <div className="flex-auto p-6 break-all">
            { nFormatter(impressions) }
          </div>
        </div>
        <div className="flex-auto flex border-b border-b-gray/20">
          <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
            First Seen - Last Seen
          </div>
          <div className="flex-auto p-6 break-all">
            { firstSeen } - { lastSeen }
          </div>
        </div>
      </div>
      <div className="flex py-5 flex-1 items-end">
        <div className="flex flex-1 items-center cursor-pointer pl-12" onClick={() => props.onAddToSwipe(id)}>
          <FavoriteIcon />
          <span className="ml-2 text-sm select-none">Add to my favorites</span>
        </div>
        <div className="flex flex-1 items-center cursor-pointer pl-12 border-l border-[rgba(159,166,183,0.3)]">
          <ExternalLinkIcon />
          <a href={url} target="_blank" rel="noreferrer" className="ml-2 text-sm select-none">See on TikTok</a>
        </div>
      </div>
    </div>
  )
})

export default TikTokPost
