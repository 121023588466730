import React from 'react'

const Button = props => {
  const classNames = ['btn']
  if (props.secondary) classNames.push('secondary')
  if (props.large) classNames.push('large')
  if (props.full) classNames.push('w-full')
  if (props.noShadow) classNames.push('no-shadow')
  if (props.red) classNames.push('red')
  if (props.green) classNames.push('green')

  return (
    <button
      onClick={props.onClick}
      type="button"
      className={props.className || classNames.join(' ')}
      style={props.style}
      disabled={props.disabled}
    >
      { props.children }
    </button>
  )
}

export default React.memo(Button)
