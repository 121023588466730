import React from 'react'
import StarActiveIcon from '~/components/icons/StarActive'
import StarGrayIcon from '~/components/icons/StarGray'

const MAX_SCORE = 5

const Button = props => {
  const activeCount = Math.round(props.score)
  const activeList = [...Array(activeCount)]
  const grayList = [...Array(MAX_SCORE - activeCount)]

  return (
    <div className="flex items-center">
      { activeList.map((_, i) => <StarActiveIcon key={`active-${i}`} />)}
      { grayList.map((_, i) => <StarGrayIcon key={`gray-${i}`} />)}
    </div>
  )
}

export default React.memo(Button)
