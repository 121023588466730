// JsFromRoutes CacheKey 9f6fb6c01bad1d27daa16e8be997fc3e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  addProducts: definePathHelper('post', '/api/swipes/:id/add_products'),
  deleteProducts: definePathHelper('post', '/api/swipes/:id/delete_products'),
  share: definePathHelper('post', '/api/swipes/:id/share'),
  unshare: definePathHelper('post', '/api/swipes/:id/unshare'),
  list: definePathHelper('get', '/api/swipes'),
  create: definePathHelper('post', '/api/swipes'),
  new: definePathHelper('get', '/api/swipes/new'),
  edit: definePathHelper('get', '/api/swipes/:id/edit'),
  get: definePathHelper('get', '/api/swipes/:id'),
  update: definePathHelper('patch', '/api/swipes/:id'),
  destroy: definePathHelper('delete', '/api/swipes/:id'),
}
