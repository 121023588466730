import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range';
import { customStaticRanges } from '../../helpers/customStaticRanges';
import 'react-date-range/dist/styles.css'; // Main style file
import 'react-date-range/dist/theme/default.css'; // Theme CSS file
import Button from '~/components/layout/Button'
import FilterIcon from '~/components/icons/Filter'

const CustomDot = ({ cx, cy }) => (
  <svg x={cx - 3} y={cy - 3} width="6" height="6" viewBox="0 0 6 6" fill="none">
    <circle cx="3" cy="3" r="2.75" fill="white" stroke="#4886EA" strokeWidth="0.5" />
    <circle cx="3" cy="3" r="2" fill="#4886EA" />
  </svg>
)

const formatInterval = value => dayjs(value).format('M/D/YY')

const SeasonalityIndicator = props => {
  const [data, setData] = useState([]);
  const [showFilters, setShowFilters] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [dateRange, setDateRange] = useState([])

  useEffect(() => {
    if(props.data && props.data.length > 0 ){
      const startDate = new Date(props.data[0].time * 1000)
      const endDate = new Date(props.data[props.data.length - 1].time * 1000)

      setData(props.data)
      setDateRange([{startDate, endDate, key: 'selection'}])
      setFilteredData(props.data.filter(data => {
        const dataDate = new Date(data.time * 1000)
        return dataDate >= startDate && dataDate <= endDate
      }))
    }
  }, [props.data])

  const handleSelect = ranges => {
    setDateRange([ranges.selection])
  }

  const applyFilters = () => {
    setShowFilters(false)
    setFilteredData(data.filter(data => {
      const dataDate = new Date(data.time * 1000)
      return dataDate >= dateRange[0].startDate && dataDate <= dateRange[0].endDate
    }))
  }

  return (
    <div className="flex flex-col w-full p-5 bg-white rounded-2xl p-5 bg-white rounded-2xl shadow-sm">
      {!!props.data && !!props.data.length &&
        <>
          <div className="flex justify-between relative">
            <div>
              <div className="mt-4 text-xs px-4">
                Interest over time
              </div>
              <div className="mt-2 text-xs text-secondary px-4">
                United States. {formatInterval(dateRange[0]?.startDate)}-{formatInterval(dateRange[0]?.endDate)}. Web Search
              </div>
            </div>
            <Button secondary onClick={() => setShowFilters(prev => !prev)}>
                <FilterIcon />
                <span className="ml-1">Date Filter</span>
            </Button>
            {showFilters &&
            <div className="z-10 mt-4 p-4 flex flex-col rounded-lg bg-white absolute top-[30px] right-0 search-trends-date-filter shadow-lg">
              <DateRangePicker
                months={1}
                direction="horizontal"
                ranges={dateRange}
                onChange={handleSelect}
                staticRanges={customStaticRanges}
                inputRanges={[]}
                showDateDisplay={true}
                minDate={new Date(data[0].time * 1000)}
                maxDate={new Date(data[data.length - 1].time * 1000)}
              />
              <div className="flex justify-end">
                <Button onClick={applyFilters}>Apply</Button>
              </div>
            </div>
            }
          </div>
          <div className="flex-auto mt-6">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={400}
                data={filteredData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid vertical={false} fill="#FFFFFF"/>
                <XAxis dataKey="time" tickLine={false} tickFormatter={value => dayjs(value * 1000).format('MMM YY')} minTickGap={50} />
                <YAxis axisLine={false} tickLine={false} domain={[0, dataMax => (dataMax * 1.2)]} />
                <Tooltip labelFormatter={value => dayjs(value * 1000).format('MMM DD, YYYY')} />
                <Line type="monotone" dataKey="value" stroke="#4886EA" fill="transparent" dot={<CustomDot />} isAnimationActive={false} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      }
    </div>
  )
}

export default SeasonalityIndicator
