const TrendingFavorite = ({ $active }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill={$active ? 'red' : 'none'}>
    <g opacity="0.6">
      <path d="M10.5167 17.3417C10.2333 17.4417 9.76667 17.4417 9.48333 17.3417C7.06667 16.5167 1.66667 13.075 1.66667 7.24171C1.66667 4.66671 3.74167 2.58337 6.3 2.58337C7.81667 2.58337 9.15833 3.31671 10 4.45004C10.8417 3.31671 12.1917 2.58337 13.7 2.58337C16.2583 2.58337 18.3333 4.66671 18.3333 7.24171C18.3333 13.075 12.9333 16.5167 10.5167 17.3417Z" stroke={$active ? 'red' : 'currentColor'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>

)

export default TrendingFavorite
