import { ReactComponent as GrowthIcon } from './growth.svg'

const NumberFormatter = new Intl.NumberFormat('en-US', { style: 'percent' })

const GrowthChart = ({ value }) => (
  <div className="flex items-center relative text-[#55D52C] h-[35px]">
    <GrowthIcon />
    <span className="absolute bottom-[3px] left-[3px] text-xs font-medium">+{ NumberFormatter.format(value || 0) }</span>
  </div>
)

export default GrowthChart
