import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import CollapseArrowIcon from '~/components/icons/CollapseArrow'

const ALL_CATEGORY = { id: 'all', name: 'All' }

const CategoriesFilter = props => {
  const products = useSelector(state => state.amazonSearchProducts.products);
  const categoryIds = products.reduce((acc, p) => acc.add(p.parent_category_id || p.category_id), new Set())
  const [isOpen, setIsOpen] = useState(false)
  const [categories, setCategories] = useState({ loading: true, data: [] })
  const [selected, setSelected] = useState([])
  const categoryCheckboxRef = useRef()

  const filteredCategories = categories.data.filter(c => categoryIds.has(c.id) || c.id === ALL_CATEGORY.id)

  useEffect(() => {
    axios.get('/api/search/categories')
    .then(response => {
      setCategories({ loading: false, data: [ALL_CATEGORY, ...response.data.amazon_categories] })
    })
  }, [])

  useEffect(() => {
    if (selected.length === 0 || selected.find(s => s === ALL_CATEGORY.id)) {
      props.onChange('category', ALL_CATEGORY.id)
    } else {
      props.onChange('category', selected)
    }
  }, [selected])

  const onCategorySelect = (id, e) => {
    e.stopPropagation()
    setSelected(prev => {
      const allCategories = filteredCategories.map(c => c.id)

      if (id === ALL_CATEGORY.id) {
        return prev.length === filteredCategories.length ? [] : allCategories
      }

      if (prev.includes(id)) {
        return prev.filter(prevId => prevId !== id)
      }

      const newCategories = [...prev, id]

      if (filteredCategories.length - 1 === newCategories.length) {
        return allCategories
      }

      return newCategories
    })
  }

  const isSelected = (id, parentId) => {
    if (id === ALL_CATEGORY.id) {
      return selected.length === filteredCategories.length
    }

    return selected.includes(id) || selected.includes(parentId)
  }

  const emptyFilter = e => {
    e.stopPropagation()
    setSelected([])
    setIsOpen(prev => !prev)
  }

  const onCategoryClick = e => {
    if (e.target === categoryCheckboxRef.current) {
      return
    }
    e.preventDefault()
    setIsOpen(prev => !prev)
  }

  return (
    <label htmlFor="category-filters-main" onClick={onCategoryClick} className="flex flex-col cursor-pointer">
      <div className="flex items-start py-4 px-4 transition-colors hover:bg-blue1/10">
        <input ref={categoryCheckboxRef} checked={!!selected.length} onChange={emptyFilter} id="category-filters-main" type="checkbox" className="w-5 h-5" />
        <div className="flex flex-auto w-full leading-4 ml-2">
          <div className="flex flex-auto items-center text-sm font-medium select-none">
            Categories
          </div>
          <div className="flex items-center w-4">
            <CollapseArrowIcon className={`duration-250 transition-transform ${isOpen ? 'rotate-[-180deg]' : 'rotate-0'}`} />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-auto items-start justify-between ml-7 px-4" onClick={e => e.stopPropagation()}>
        <div className={`border-b border-b-blue1/20 flex flex-col w-full transition-[max-height] ease-[cubic-bezier(0,1,0,1)] duration-250 ${isOpen ? 'ease-in-out max-h-[300px] mb-4 pb-4' : 'max-h-0'}`}>
          <div className="overflow-y-auto">
            {
              filteredCategories.map(category => (
                <div key={category.id} className="flex my-2 mt-4 last:mb-0">
                  <input id={`category-filters-checkbox-${category.id}`} checked={isSelected(category.id)} onChange={onCategorySelect.bind(this, category.id)} type="checkbox" className="w-5 h-5" />
                  <label htmlFor={`category-filters-checkbox-${category.id}`} className="flex flex-auto w-full leading-4 ml-2">
                    <div className="flex w-full">
                      <div className="flex flex-col flex-auto">
                        <div className="flex flex-auto items-center text-sm font-medium select-none">{ category.name }</div>
                      </div>
                    </div>
                  </label>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </label>
  )
}

export default CategoriesFilter
