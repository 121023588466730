// JsFromRoutes CacheKey f841b4627fc436541510c8984b3143f7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/tik_tok_posts'),
  create: definePathHelper('post', '/api/tik_tok_posts'),
  new: definePathHelper('get', '/api/tik_tok_posts/new'),
  edit: definePathHelper('get', '/api/tik_tok_posts/:id/edit'),
  get: definePathHelper('get', '/api/tik_tok_posts/:id'),
  update: definePathHelper('patch', '/api/tik_tok_posts/:id'),
  destroy: definePathHelper('delete', '/api/tik_tok_posts/:id'),
}
