// JsFromRoutes CacheKey c5a761aa1d65711839968bf120e7c036
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  trending: definePathHelper('get', '/api/dashboard/trending'),
  advertisers: definePathHelper('get', '/api/dashboard/advertisers'),
  ads: definePathHelper('get', '/api/dashboard/ads'),
  adProducts: definePathHelper('get', '/api/dashboard/ad_products'),
  ourPicks: definePathHelper('get', '/api/dashboard/our_picks'),
  seasonalTrending: definePathHelper('get', '/api/dashboard/seasonal_trending'),
}
