import { useState } from 'react'
import CategoriesFilter from './Filters/CategoriesFilter'
import RangeFilter from './Filters/RangeFilter'
import Button from '~/components/layout/Button'
import ArrowLeft from '~/components/icons/ArrowLeft'
import SearchIcon from '~/components/icons/Search'

const Filters = props => {
  const [filters, setFilters] = useState(props.filters)

  const onChange = (field, value) => {
    setFilters(prev => ({ ...prev, [field]: value }))
  }

  const onApply = () => {
    props.onChange(filters)
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <div className="flex flex-col h-full py-5">
      <div className="flex items-center px-4">
        <div className="mr-4" onClick={props.onClose}><ArrowLeft /></div>
        <div className="flex text-xl">Filters</div>
      </div>
      <div className="flex mt-6 w-full px-4">
        <div className="flex flex-1">
          <div className="input-group relative flex items-center w-full rounded-lg bg-white h-9 px-1.5 shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
            <SearchIcon className="ml-3" />
            <input type="search" className="form-control h-[30px] relative flex-auto min-w-0 block w-full px-2 text-base font-normal bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-auto mt-6 overflow-y-auto">
        { !props.hideCategoryFilter && <CategoriesFilter onChange={onChange} /> }
        <RangeFilter id="monthly_revenue" name="Monthly Revenue" onChange={onChange} inCents />
        <RangeFilter id="price" name="Price" onChange={onChange} inCents />
        <RangeFilter id="review_count" name="Review Count" onChange={onChange} />
        <RangeFilter id="review_rating" name="Review Rating" onChange={onChange} />
        <RangeFilter id="monthly_sales" name="Monthly Sales (Units)" onChange={onChange} />
        <RangeFilter id="bestseller_rank" name="Best Seller Rank (BSR)" onChange={onChange} />
        <RangeFilter id="images_count" name="Number of Images" onChange={onChange} />
        <RangeFilter id="variants_count" name="Variation Count" onChange={onChange} />
      </div>
      <div className="flex flex-col flex-0 mt-5 px-4">
        <div className="flex flex-1">
          <div className="flex flex-1">
            <Button large full className="w-full" style={{ height: 48 }} onClick={onApply}>Apply</Button>
          </div>
          <div className="flex flex-1 ml-6">
            <Button large full secondary className="w-full" style={{ height: 48 }} onClick={onCancel}>Cancel</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filters
