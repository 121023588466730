import { useState, useEffect, useRef } from 'react'
import CollapseArrowIcon from '~/components/icons/CollapseArrow'

const RangeFilter = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [min, setMin] = useState('')
  const [max, setMax] = useState('')
  const categoryCheckboxRef = useRef()

  useEffect(() => {
    const result = {}
    const resultMin = parseInt(min)
    const resultMax = parseInt(max)

    if (resultMin) {
      result.min = resultMin
      if (props.inCents) {
        result.min *= 100
      }
    }
    if (resultMax) {
      result.max = resultMax
      if (props.inCents) {
        result.max *= 100
      }
    }
    props.onChange(props.id, result)
  }, [min, max])

  const onCategoryClick = e => {
    if (e.target === categoryCheckboxRef.current) {
      return
    }
    e.preventDefault()
    setIsOpen(prev => !prev)
  }

  const emptyFilter = () => {
    setMin('')
    setMax('')
    setIsOpen(prev => !prev)
  }

  const onMinChange = e => {
    setMin(e.target.value)
  }

  const onMaxChange = e => {
    setMax(e.target.value)
  }

  return (
    <label htmlFor="category-filters-main" onClick={onCategoryClick} className="flex flex-col cursor-pointer">
      <div className="flex items-start py-4 px-4 transition-colors hover:bg-blue1/10">
        <input ref={categoryCheckboxRef} checked={min.length || max.length} onChange={emptyFilter} id="category-filters-main" type="checkbox" className="w-5 h-5" />
        <div className="flex flex-auto w-full leading-4 ml-2">
          <div className="flex flex-auto items-center text-sm font-medium select-none">
            { props.name }
          </div>
          <div className="flex items-center w-4">
            <CollapseArrowIcon className={`duration-250 transition-transform ${isOpen ? 'rotate-[-180deg]' : 'rotate-0'}`} />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-auto items-start justify-between ml-7 px-4" onClick={e => e.stopPropagation()}>
        <div className={`flex flex w-full overflow-y-auto transition-[max-height] ease-[cubic-bezier(0,1,0,1)] duration-250 ${isOpen ? 'ease-in-out max-h-[300px]' : 'border-b border-b-blue1/20 max-h-0'}`}>
          <div className="flex flex-1">
            <div className="flex-1">
              <input placeholder="Min" value={min} onChange={onMinChange} type="number" className="flex flex-1 w-full py-2 rounded-none border-b border-b-blue1/20 outline-0 text-sm" />
            </div>
            <div className="flex-1 ml-6">
              <input placeholder="Max" value={max} onChange={onMaxChange} type="number" className="flex flex-1 w-full py-2 rounded-none border-b border-b-blue1/20 outline-0 text-sm" />
            </div>
          </div>
        </div>
      </div>
    </label>
  )
}

export default RangeFilter
