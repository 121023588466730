import { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

const Modal = ({ children, isOpen, onClose, headerText }) => {
  const modalNode = useRef()

  useEffect(() => {
    if (isOpen) {
      modalNode.current.classList.remove('opacity-0')
      modalNode.current.classList.remove('pointer-events-none')
    } else {
      modalNode.current.classList.add('opacity-0')
      modalNode.current.classList.add('pointer-events-none')
    }
  }, [isOpen])

  return createPortal(
    <div ref={modalNode} className="transition-opacity opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center z-20">
      <div className="modal-overlay absolute w-full h-full bg-primary/85" onClick={onClose}></div>
      <div className="modal-container max-h-[90vh] bg-gray1 w-11/12 md:max-w-2xl mx-auto rounded-[10px] shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left">
          {headerText?.length &&
            <div className="flex justify-between items-center pb-6">
              <p className="text-xl">{ headerText }</p>
              <div className="modal-close cursor-pointer z-50" onClick={onClose}>
                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
              </div>
            </div>
          }
          { children }
        </div>
      </div>
    </div>,
    document.getElementById('modal-root'))
}

export default Modal
