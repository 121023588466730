// JsFromRoutes CacheKey 28f6e76ca166244506807f2bdea08712
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  deleteProducts: definePathHelper('delete', '/api/products/delete_products'),
  list: definePathHelper('get', '/api/products'),
  create: definePathHelper('post', '/api/products'),
  new: definePathHelper('get', '/api/products/new'),
  edit: definePathHelper('get', '/api/products/:id/edit'),
  get: definePathHelper('get', '/api/products/:id'),
  update: definePathHelper('patch', '/api/products/:id'),
  destroy: definePathHelper('delete', '/api/products/:id'),
}
