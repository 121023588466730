import { createSlice } from '@reduxjs/toolkit';

const updateSwipeSlice = createSlice({
  name: 'updateSwipe',
  initialState: {
    update_swipe: false,
  },
  reducers: {
    setUpdateSwipe: (state, action) => {
      state.update_swipe = action.payload;
    },
  },
});

export const { setUpdateSwipe } = updateSwipeSlice.actions;
export const selectUpdateSwipe = (state) => state.updateSwipe.update_swipe;

export default updateSwipeSlice.reducer;
