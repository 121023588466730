import { useState } from 'react'
import ArrowDownIcon from '~/components/icons/ArrowDown'

const Collapse = props => {
  const [isOpen, setOpen] = useState(false)

  const Icon = props.icon

  return (
    <div className="flex flex-col border-b border-[#9FA6B7]/30">
      <div className="flex items-center justify-between cursor-pointer p-6 pb-6" onClick={() => setOpen(prev => !prev)}>
        <div className="flex items-center">
          <div className="flex items-center justify-center text-[#3F71EF] w-12 h-12 bg-[rgba(63,113,239,0.10)] rounded-full"><Icon /></div>
          <div className="ml-2.5 flex flex-col">
            <div className="text-sm font-medium">{ props.title }</div>
            <div className="mt-1 text-[#49546D] text-xs">{ props.description }</div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-4">{ props.action }</div>
          <div><ArrowDownIcon width="24px" height="24px" /></div>
        </div>
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} p-6 pt-0`}>
        { props.children }
      </div>
    </div>
  )
}

export default Collapse
