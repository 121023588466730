import { useEffect, useRef, useState } from 'react'
import ProgressiveImage from 'react-progressive-graceful-image'
import { Tooltip } from 'react-tooltip'
import lookup from 'country-code-lookup'
import { nFormatter } from '~/helpers/formatter'
import Button from '~/components/layout/Button'
import TrendingFavoriteIcon from '~/components/icons/TrendingFavorite'
import Checkbox from '~/components/layout/Checkbox'
import AudienceIcon from '~/components/icons/Audience'
import CalendarIcon from '~/components/icons/Calendar'
import PlusIcon from '~/components/icons/Plus'
import TrashIcon from '~/components/icons/Trash'
import TikTokLogo from '~/components/icons/TikTokLogo'
import InstagramLogo from '~/components/icons/InstagramLogo'
import FacebookLogo from '~/components/icons/FacebookLogo'
import Carousel from '~/components/Home/Carousel'

const getCountry = code => {
  if (!code) return ''

  return lookup.byIso(code)?.country || 'ALL'
}

const LOGOS = {
  'tik_tok': <TikTokLogo width="11" height="12.43" />,
  'instagram': <InstagramLogo width="10" height="10" />,
  'facebook': <FacebookLogo width="15" height="15"/>,
}

const newVideoHeight = 300

const AdCard = props => {
  // const [ad, setAd] = useState(props.ad)
  const [itemWidth, setItemWidth] = useState(0);
  const { ad } = props
  const videoRef = useRef()
  const containerRef = useRef()

  const handleClick = () => {
    if (typeof props.onClick === 'function') {
      props.onClick(ad.id)
    }
  }

  // Function to update the container's width
  const updateContainerWidth = () => {
    if (containerRef.current) {
      const containerRect = containerRef.current.offsetWidth;
      setItemWidth(containerRect);
    }
  };

  useEffect(() => {
    // Initial call to set the container width
    updateContainerWidth();

    // Listen for the window resize event and update the container width
    window.addEventListener('resize', updateContainerWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, [containerRef.current]);

  const getVideoWidth = () => {
    if (!ad.size?.length) return 170

    const [videoWidth, videoHeight] = ad.size.split('/')
    return videoWidth * newVideoHeight / videoHeight
  }

  const onVideoMouseEnter = () => {
    videoRef.current.play()
  }

  const onVideoMouseLeave = () => {
    videoRef.current.pause()
  }

  const onVideoClick = e => {
    e.preventDefault()
    e.stopPropagation()
    e.target.muted = !e.target.muted
  }

  const addToSwipe = e => {
    e.stopPropagation()

    props.addToSwipe(ad.id)
  }

  const onSelect = () => {
    if (props.isSelected) {
      props.onUnselect(ad.id)
    } else {
      props.onSelect(ad.id)
    }
  }

  return (
    <div className="relative flex flex-col w-full mt-6 xl:p-5 p-3 cursor-pointer rounded-lg bg-white shadow-[0_4px_50px_rgba(0,0,0,0.05)]" onClick={handleClick}>
      <div className="flex">
        <div className="flex w-full">
          <div className="rounded-full w-[34px] h-[30px] relative flex-shrink-0">
            <img className="rounded-full h-[30px] w-[30px] bg-gray" src={ad.advertiser?.logoUrl} />
            {
              ad.adType &&
              <div className='rounded-full border-[1.5px] border-white w-[20px] h-[20px] bg-[#ECEFF3] right-0 bottom-0 absolute flex justify-center items-center'>
                { LOGOS[ad.adType] }
              </div>
            }
          </div>
          <div className="flex flex-col ml-2 w-full">
            <div className="flex items-start justify-between">
              <div className="text-xs font-medium leading-[13px] break-all">
                { ad.advertiser?.name }
              </div>
              {!!ad.objective?.length &&
                <div className="ml-2 bg-gray1 rounded-[10px] py-1 px-2 text-[10px] leading-[11px] whitespace-nowrap">
                  { ad.objective }
                </div>
              }
            </div>
            <div className="mt-1 text-[10px] leading-[11px] text-secondary">{ getCountry(ad.countryCode) }</div>
          </div>
        </div>
        {typeof props.addToSwipe === 'function' &&
          <div className="flex-0 text-gray ml-2" onClick={addToSwipe}>
            <TrendingFavoriteIcon $active={props.isFavourite} />
          </div>
        }
        {typeof props.toggleSelect === 'function' &&
          <div className="flex-0 text-gray ml-2" >
            <Checkbox onClick={e => e.stopPropagation()} checked={props.selected} onChange={() => props.toggleSelect(ad.id,!props.selected)} />
          </div>
        }
      </div>
      { (() => {
          switch (ad.adFormat) {
            case 'video':
              return (
                <div onMouseEnter={onVideoMouseEnter} onMouseLeave={onVideoMouseLeave} className="flex justify-center mt-4 relative">
                  <ProgressiveImage src={ad.coverUrl} placeholder="">
                    {(src, loading) => (
                      loading
                      ? <div className="rounded-lg w-full min-w-full h-[300px] bg-gray opacity-10" />
                      : <img src={src} className="rounded-lg w-full min-w-full h-[300px] opacity-25" />
                    )}
                  </ProgressiveImage>
                  <video onClick={onVideoClick} muted loop ref={videoRef} width={getVideoWidth()} height={newVideoHeight} className="absolute top-0 left-1/2 -translate-x-1/2 h-[300px]" controls poster={ad.coverUrl} preload="none">
                    <source src={ad.videoUrl} type="video/mp4" />
                  </video>
                </div>
              )

            case 'carousel':
              return (
                <div className="w-full" ref={containerRef}>
                  <Carousel containerPadding={'px-0 mt-4'} itemWidth={itemWidth}>
                    {
                      ad.carouselImages.map((image, index) => (
                        <div style={{ width: `${itemWidth}px`, minWidth: `${itemWidth}px` }} className="relative" key={index}>
                          <div className="rounded-lg w-full min-w-full h-[300px] bg-gray opacity-10" />
                          <img className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-auto max-h-full" src={image} />
                        </div>
                      ))
                    }
                  </Carousel>
                </div>
              )

            case 'image':
              return (
                  <div className="flex justify-center mt-4 relative">
                    <div className="rounded-lg w-full min-w-full h-[300px] bg-gray opacity-10" />
                    <img className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-auto max-h-full" src={ad.coverUrl} />
                  </div>
                )

            default: {
              return (
                <div className="flex justify-center mt-4 relative">
                  <div className="rounded-lg w-full min-w-full h-[300px] bg-gray opacity-10" />
                  <img className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-auto max-h-full" src={ad.coverUrl} />
                </div>
              )
            }
          }
        })()
      }
      <div className="flex flex-col my-4">
        <div className="flex items-center">
          <div className="flex-auto text-[20px] leading-8 truncate min-h-[30px]" data-tooltip-id="title-tooltip" data-tooltip-content={ad.title}>
            { ad.title }
          </div>
          <div className="flex-0 w-[80px] max-w-[80px]">
            <a onClick={e => e.stopPropagation()} href={ad.finalUrl || ad.bioUrl} rel="noreferrer" target="_blank" className="leading-none block text-[10px] ml-1 px-2 py-1.5 rounded truncate border border-gray1 hover:border-gray">
              { ad.ctaButton }
            </a>
          </div>
        </div>
        <div className="mt-2 text-[10px] flex items-center">
          <CalendarIcon />
          <span className="ml-1">{ ad.firstSeen } - { ad.lastSeen }</span>
        </div>
        <div className="mt-2 text-[10px] flex items-center">
          <AudienceIcon />
          <span className="ml-1">
            { !!ad.age?.length && <span>{ ad.age }<span className="mx-1">&#x2022;</span></span> }
            <span>{ ad.gender }</span>
          </span>
        </div>
      </div>
      <div className="flex flex-0 w-full py-[11px] border-t border-gray2 justify-between items-center font-medium">
        <div className="flex-1 flex flex-col items-center">
          <div className="">{ nFormatter(ad.impressions) }</div>
          <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Ad Impressions</div>
        </div>
        <div className="flex-1 flex flex-col items-center">
          <div className="">{ ad.seenDiff }</div>
          <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Days</div>
        </div>
        <div className="flex-1 flex flex-col items-center">
          <div className="">{ nFormatter(ad.popularity) }</div>
          <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Popularity</div>
        </div>
      </div>
      {(props.onSelect || props.onUnselect) &&
        <div className="flex flex-0 w-full mt-2 justify-between items-center font-medium">
          <Button full secondary red={props.isSelected} onClick={onSelect}>
            <div className="w-full flex items-center justify-center">
              {
                props.isSelected
                ? <>
                  <TrashIcon width="18px" height="18px" color="#F72E3A" />
                  <div className="ml-1.5 text-sm text-[#F72E3A]">Remove</div>
                </>
                : <>
                  <PlusIcon />
                  <div className="text-sm">Add</div>
                </>
              }
            </div>
          </Button>
        </div>
      }
      <Tooltip id="title-tooltip" variant='dark' place='top' positionStrategy='fixed' style={{ maxWidth: 300, fontSize: 12 }} />
    </div>
  )
}

export const FakeAd = () => {
  return (
  <div className="animate-pulse relative h-[560px] flex flex-col w-full mt-6 xl:p-5 p-3 rounded-lg bg-white shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
    <div className="flex">
      <div className="flex w-full">
        <div className="rounded-full w-[30px] h-[30px] bg-slate-200 dark:bg-slate-700 flex-shrink-0" />
        <div className="ml-2 h-full w-[100px] bg-slate-200 dark:bg-slate-700" />
      </div>
    </div>
    <div className="flex justify-center mt-4 relative">
      <div className="rounded-lg w-full min-w-full h-[300px] bg-slate-200 dark:bg-slate-700" />
    </div>
    <div className="flex flex-col my-4">
      <div className="h-[32px] w-[300px] bg-slate-200 dark:bg-slate-700" />
      <div className="mt-2 h-[16px] w-[130px] bg-slate-200 dark:bg-slate-700" />
      <div className="mt-2 h-[16px] w-[130px] bg-slate-200 dark:bg-slate-700" />
    </div>
    <div className="flex flex-0 w-full py-[11px] border-t border-gray2 justify-between items-center font-medium">
    <div className="h-[40px] w-[80px] bg-slate-200 dark:bg-slate-700" />
    <div className="h-[40px] w-[80px] bg-slate-200 dark:bg-slate-700" />
    <div className="h-[40px] w-[80px] bg-slate-200 dark:bg-slate-700" />
    </div>
  </div>
  )
}
export default AdCard
