import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setUpdateSwipe } from '~/store/reducers/updateSwipeSlice';
import api from '~/api'
import Modal from '~/components/layout/Modal'
import Button from '~/components/layout/Button'
import Select from '~/components/layout/Select'
import Input from '~/components/layout/Input'
import AddIcon from '~/components/icons/Add'
import LoadingIcon from '~/components/icons/Loading'

const NewListForm = props => {
  const [groupId, setGroupId] = useState(props.groups[0]?.value)
  const [name, setName] = useState('')
  const [creating, setCreating] = useState(false)
  const dispatch = useDispatch();

  const onSubmit = () => {
    setCreating(true)
    api.apiSwipes.create({ data: { swipe_group_id: groupId, swipe: { name } } })
    .then(data => {
      props.onCreate(groupId, data.swipe)
      setCreating(false)
      dispatch(setUpdateSwipe(true))
    })
    .catch(() => {
      setCreating(false)
    })
  }

  return (
    <div className="flex">
      <div className="">
        <Select options={props.groups} value={groupId} onChange={e => setGroupId(Number(e.target.value))} />
      </div>
      <div className="ml-2">
        <Input placeholder="New Swipe Name" value={name} onChange={e => setName(e.target.value)} />
      </div>
      <div className="ml-2">
        <Button disabled={creating || !name?.length} secondary noShadow onClick={onSubmit}>Create</Button>
      </div>
    </div>
  )
}

const AddToListing = props => {
  const [swipeGroups, setSwipeGroups] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedSwipeId, setSelectedSwipeId] = useState()
  const [newListForm, setNewListForm] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if(newListForm) return
    api.apiSwipeGroups.list({ query: { product_ids: props.selectedIds } })
    .then(data => {
      setSwipeGroups(data.swipeGroups)
      setSelectedSwipeId(data.swipeGroups[0]?.swipes[0]?.id)
    })
  }, [props.selectedIds, newListForm])

  useEffect(() => {
    setModalOpen(true)

    return () => {
      setModalOpen(false)
    }
  }, [])

  const onSwipeChange = e => {
    const swipeId = parseInt(e.target.value)
    setSelectedSwipeId(swipeId)
  }

  const onClose = () => {
    // setModalOpen(false)
    props.onClose()
  }

  const onSubmit = () => {
    setSubmitting(true)
    props.onSubmit(selectedSwipeId, () => {
      setSubmitting(false)
    })
  }

  const onNewList = () => {
    setNewListForm(true)
  }

  const onCreateList = (groupId, swipe) => {
    setNewListForm(false)
  }

  const swipeGroupsOptions = swipeGroups.map(group => ({ value: group.id, label: group.name }))

  const swipesOptions = []
  swipeGroups.forEach(group => {
    swipesOptions.push({
      label: group.name,
      options: group.swipes.filter(l => !props.exceptSwipeIds.includes(l.id)).map(swipe => ({ value: swipe.id, label: swipe.name }))
    })
  })

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} headerText={ swipeGroups.length > 0 ? "Choose list" : "Create Swipe Folder form sidebar first"}>
      <div className="flex flex-col">
        <div className="flex text-secondary">Select Swipe</div>
        <div className="flex mt-2"><Select options={swipesOptions} value={selectedSwipeId} onChange={onSwipeChange} /></div>
        <div className="flex mt-4 items-center cursor-pointer">
          {
            newListForm
            ? <NewListForm groups={swipeGroupsOptions} onCreate={onCreateList} />
            : <div className="flex" onClick={onNewList}>
              <AddIcon />
              <span className="ml-2 text-sm">Add list</span>
            </div>
          }
        </div>
        <div className="flex flex-1 mt-6">
          <div className="flex flex-1">
            <Button full secondary onClick={onClose}>Cancel</Button>
          </div>
          <div className="flex flex-1 ml-6">
            <Button disabled={submitting || !selectedSwipeId} full onClick={onSubmit}>
              { submitting ? <><LoadingIcon /> Submitting...</> : 'Submit' }
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
AddToListing.defaultProps = {
  exceptSwipeIds: []
}

export default AddToListing
