import KeywordTable from '~/components/Research/Keywords/Table'
import { CSVLink } from 'react-csv'
import Button from '~/components/layout/Button'
import ReactPaginate from 'react-paginate'
import { useState } from 'react'

const itemsPerPage = 10

const Keywords = props => {
  const [pageOffset, setPageOffset] = useState(0)

  const onPageChange = e => {
    setPageOffset(e.selected)
  }

  const endOffset = (pageOffset + 1) * itemsPerPage
  const paginatedSearches = props.relatedSearches?.slice(pageOffset * itemsPerPage, endOffset)
  const pageCount = Math.ceil(props.relatedSearches?.length / itemsPerPage)
  return (
    <>
      {props.relatedSearches && <div className='flex justify-end items-center my-7'>
        <Button secondary noShadow>
          <CSVLink data={props.relatedSearches} filename="export.csv">
            Export
          </CSVLink>
        </Button>
      </div>}
      {paginatedSearches && <KeywordTable relatedSearches={paginatedSearches} />}
      {pageCount > 1 &&
        <ReactPaginate
          onPageChange={onPageChange}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          className="pagination justify-end mt-4"
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={pageOffset}
        />
      }
    </>
  )
}

export default Keywords
