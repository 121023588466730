import React from 'react'

const renderOption = (index, value, label, disabled) => (
  <option
    key={index}
    value={value}
    disabled={disabled}
  >
    { label }
  </option>
)

const Select = props => {
  const classNames = [props.className, 'select w-full h-9 px-[16px] rounded-lg text-sm outline-none']
  // if (props.secondary) classNames.push('secondary')
  // if (props.large) classNames.push('large')
  // if (props.full) classNames.push('w-full')

  return (
    <select
      onChange={props.onChange}
      value={props.value}
      className={classNames.join(' ')}
      style={props.style}
      autoFocus={true}
    >
      {
        props.options.map((option, index) => {
          if (option.options) {
            return (
              <optgroup key={index} label={option.label}>
                {
                  option.options.map((suboption, subindex) => (
                    renderOption(subindex, suboption.value, suboption.label, suboption.disabled)
                  ))
                }
              </optgroup>
            )
          }

          return renderOption(index, option.value, option.label, option.disabled)

          // <option key={option.value} value={option.value}>{ option.label }</option>
        })
      }
    </select>
  )
}

export default React.memo(Select)
