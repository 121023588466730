const ArrowLeft = props => (
  <svg width={props.width || '16px'} height={props.height || '16px'} viewBox="0 0 16 16" fill="none">
    <path d="M5.33333 1.33398V3.33398" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.6667 1.33398V3.33398" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.33333 6.06055H13.6667" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 5.66732V11.334C14 13.334 13 14.6673 10.6667 14.6673H5.33333C3 14.6673 2 13.334 2 11.334V5.66732C2 3.66732 3 2.33398 5.33333 2.33398H10.6667C13 2.33398 14 3.66732 14 5.66732Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.4631 9.13411H10.4691" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.4631 11.1341H10.4691" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.99698 9.13411H8.00297" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.99698 11.1341H8.00297" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.52955 9.13411H5.53553" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.52955 11.1341H5.53553" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ArrowLeft
