import Rating from '~/components/layout/Rating'
import Button from '~/components/layout/Button'
import Checkbox from '~/components/layout/Checkbox'

const USDFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})
const NumberFormatter = new Intl.NumberFormat('en-US')

const Product = props => {
  const { asin, title, url, raw, price, salesEstimation, bestsellerRank, grossProfit, sellerType, reviewCount, reviewScore, category, swipesIds } = props.product

  const onProductClick = () => {
    props.onClick?.(asin)
  }

  const onAdd = e => {
    e.stopPropagation()
    props.onAdd(asin)
  }

  const toggleSelect = () => {
    props.toggleSelect(asin)
  }

  return (
    <div key={asin} onClick={onProductClick} className="flex h-[92px] bg-white text-sm shadow-sm cursor-pointer transition-colors border-t border-gray2 last:rounded-b-lg">
      <div className="flex basis-[50px] items-center justify-center">
        <div className="flex items-center">
          <Checkbox onClick={e => e.stopPropagation()} checked={props.checked} onChange={toggleSelect} />
        </div>
      </div>
      <div className="2xl:basis-[330px] xl:basis-[280px] basis-[230px] 2xl:max-w-[330px] xl:max-w-[280px] max-w-[230px] flex items-center">
        <div className="flex-none w-[70px] h-[70px] bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${raw.image})` }} />
        <div className="flex flex-col truncate ml-4">
          <div className="items-center truncate text-xs text-secondary">
            { category }
          </div>
          <div className="items-center truncate 2xl:text-base text-sm">
            { title }
          </div>
          <div className="items-center truncate font-medium text-xs">
            <a className="text-blue" href={url} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>{ asin }</a>
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">{ USDFormatter.format(price / 100) }</div>
      <div className="flex-1 flex items-center justify-center">{ salesEstimation?.monthlySalesEstimate > 0 ? salesEstimation?.monthlySalesEstimate : "N/A" }</div>
      <div className="flex-1 flex items-center justify-center">{ salesEstimation?.monthlySalesEstimate > 0 ? salesEstimation?.monthlySalesEstimate && USDFormatter.format(salesEstimation.monthlySalesEstimate * price / 100) : "N/A" }</div>
      <div className="flex-1 flex items-center justify-center">{ bestsellerRank }</div>
      <div className="flex-1 flex items-center justify-center">{ USDFormatter.format(grossProfit / 100) }</div>
      <div className="flex-1 flex items-center justify-center">{ sellerType }</div>
      <div className="flex-1 flex-col flex items-end justify-center">
        <div className="flex flex-col items-center justify-center text-secondary text-xs">
          { NumberFormatter.format(reviewCount) }
        </div>
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center mr-2 text-sm font-medium">
            { reviewScore }
          </div>
          <Rating score={reviewScore} />
        </div>
      </div>
      {
        props.onAdd
        ? <div className="flex basis-[100px] items-center justify-center">
          <Button green={swipesIds.length} onClick={onAdd}>{ swipesIds.length ? 'Added' : 'Add' }</Button>
        </div>
        : <div className="w-5"></div>
      }
    </div>
  )
}

export const FakeProduct = () => (
  <div className="animate-pulse flex h-[70px] bg-white rounded-lg mb-2 text-base shadow-sm cursor-pointer transition-colors border border-[transparent] hover:border-blue1">
    <div className="flex basis-[50px] items-center justify-center">
      <div className="flex items-center">
        <div className="w-5 h-5 bg-slate-200 dark:bg-slate-700 rounded"></div>
      </div>
    </div>
    <div className="basis-1/4 max-w-xs flex items-center">
      <div className="min-w-[40px] h-10 bg-slate-200 dark:bg-slate-700 rounded"></div>
      <div className="w-60 ml-4 h-5 bg-slate-200 dark:bg-slate-700 rounded"></div>
    </div>
    <div className="flex-1 flex items-center justify-center"><div className="w-16 h-5 bg-slate-200 dark:bg-slate-700 rounded"></div></div>
    <div className="flex-1 flex items-center justify-center"><div className="w-20 h-5 bg-slate-200 dark:bg-slate-700 rounded"></div></div>
    <div className="flex-1 flex items-center justify-center"><div className="w-16 h-5 bg-slate-200 dark:bg-slate-700 rounded"></div></div>
    <div className="flex-1 flex-col flex items-end justify-center">
      <div className="flex flex-col items-center justify-center text-secondary text-xs">
        <div className="w-16 h-3 bg-slate-200 dark:bg-slate-700 rounded"></div>
      </div>
      <div className="flex items-center justify-center mt-2">
        <div className="w-32 h-3 bg-slate-200 dark:bg-slate-700 rounded"></div>
      </div>
    </div>
    <div className="flex basis-[100px] items-center justify-center"><div className="w-20 h-9 bg-slate-200 dark:bg-slate-700 rounded"></div></div>
  </div>
)

export default Product
