// JsFromRoutes CacheKey 50111f9f3f50a8cf9ea15d7438ca2c77
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/brief_brands'),
  create: definePathHelper('post', '/api/brief_brands'),
  new: definePathHelper('get', '/api/brief_brands/new'),
  edit: definePathHelper('get', '/api/brief_brands/:id/edit'),
  get: definePathHelper('get', '/api/brief_brands/:id'),
  update: definePathHelper('patch', '/api/brief_brands/:id'),
  destroy: definePathHelper('delete', '/api/brief_brands/:id'),
}
