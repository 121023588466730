const Filter = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8334 3.79199H9.33337" stroke="#5693F5" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.49996 3.79199H1.16663" stroke="#5693F5" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.83329 5.83333C6.96087 5.83333 7.87496 4.91925 7.87496 3.79167C7.87496 2.66409 6.96087 1.75 5.83329 1.75C4.70571 1.75 3.79163 2.66409 3.79163 3.79167C3.79163 4.91925 4.70571 5.83333 5.83329 5.83333Z" stroke="#4A88EB" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.8333 10.208H10.5" stroke="#4A88EB" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.66663 10.208H1.16663" stroke="#4A88EB" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.16667 12.2503C9.29425 12.2503 10.2083 11.3362 10.2083 10.2087C10.2083 9.08108 9.29425 8.16699 8.16667 8.16699C7.03909 8.16699 6.125 9.08108 6.125 10.2087C6.125 11.3362 7.03909 12.2503 8.16667 12.2503Z" stroke="#4A88EB" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default Filter
