import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { deepConvertKeys, camelCase } from '@js-from-routes/core'
import api from '~/api'
import consumer from '~/channels/consumer'
import NotificationManager from '~/services/NotificationManager'
import Drawer from '~/components/layout/Drawer'
import Tabs from '~/components/layout/Tabs'
import Filters from '~/components/Research/Filters'
import AliExpressFilters from '~/components/Research/AliExpressFilters'
import AddToListing from '~/components/Research/AddToListing'
import BulkActions from '~/components/Research/BulkActions'
import SeasonalityIndicator from './SeasonalityIndicator'
import Keywords from './Keywords'
import { useDispatch, useSelector } from 'react-redux';
import {
  setProducts as setAmazonProducts,
  appendProduct as appendAmazonProduct,
  setSelectedProducts as setSelectedAmazonProducts,
  setFilters as setAmazonFilters,
  setPagination as setAmazonPagination,
  setModalOpen as setAmazonModalOpen,
  setModalClose as setAmazonModalClose
} from '~/store/reducers/amazonSearchProductsSlice';
import {
  setProducts as setAliExpressProducts,
  appendProduct as appendAliExpressProduct,
  setSelectedProducts as setSelectedAliExpressProducts,
  setFilters as setAliExpressFilters,
  setPagination as setAliExpressPagination,
  setModalOpen as setAliExpressModalOpen,
  setModalClose as setAliExpressModalClose
} from '~/store/reducers/aliExpressSearchProductsSlice';
import AmazonSearchProduct from './AmazonSearchProduct'
import AliExpressSearchProduct from './AliExpressSearchProduct'
import TikTokPostSearch from './TikTokPostSearch'
export const NumberFormatter = new Intl.NumberFormat('en-US')
export const USDFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const tabsOptions = [
  { value: 'amazonProducts', label: 'Amazon Products' },
  { value: 'aliExpressProducts', label: 'AliExpress Suppliers' },
  { value: 'tiktok', label: 'TikTok Search' },
  { value: 'trends', label: 'Trends' },
  { value: 'keywords', label: 'Suggested Terms' },
]

const Research = props => {
  const { search } = props
  const [activeTab, setActiveTab] = useState('amazonProducts')
  const [searchParams, setSearchParams] = useSearchParams()
  const productAsin = searchParams.get('asin')
  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const [trends, setTrends] = useState({ relatedSearches: search.relatedSearches || [], trendsChartData: search.trendsChartData || [] })
  const amazonProducts = useSelector(state => state.amazonSearchProducts.products);
  const selectedAmazonProduct = useSelector(state => state.amazonSearchProducts.selectedProduct);
  const selectedAmazonProducts = useSelector(state => state.amazonSearchProducts.selectedProducts);
  const isAmazonModalOpen = useSelector(state => state.amazonSearchProducts.swipeModalOpen);
  const amazonSortBy = useSelector(state => state.amazonSearchProducts.sortBy);
  const amazonFilters = useSelector(state => state.amazonSearchProducts.filters);
  const amazonPage = useSelector(state => state.amazonSearchProducts.page);
  const aliExpressProducts = useSelector(state => state.aliExpressSearchProducts.products);
  const selectedAliExpressProduct = useSelector(state => state.aliExpressSearchProducts.selectedProduct);
  const selectedAliExpressProducts = useSelector(state => state.aliExpressSearchProducts.selectedProducts);
  const isAliExpressModalOpen = useSelector(state => state.aliExpressSearchProducts.swipeModalOpen);
  const aliExpressSortBy = useSelector(state => state.aliExpressSearchProducts.sortBy);
  const aliExpressFilters = useSelector(state => state.aliExpressSearchProducts.filters);
  const aliExpressPage = useSelector(state => state.aliExpressSearchProducts.page);
  const [progress, setProgress] = useState({
    loading: search.status === 'fetching',
    progress: 0
  })
  const [tikTokPosts, setTikTokPosts] = useState([])
  const [selectedPost, setSelectedPost] = useState(null)

  const dispatch = useDispatch();
  const onModalClose = () => {
    activeTab === "amazonProducts" ? dispatch(setAmazonModalClose()) : dispatch(setAliExpressModalClose())
  }

  const getProducts = () => {
    api.apiSearch.searchAmazonProducts({ id: search.id, query: { page: amazonPage, sortBy: amazonSortBy, filters: amazonFilters } }).then(data => {
      dispatch(setAmazonProducts(data.search.products))
      dispatch(setAmazonPagination(data.search.pagination))
    }).catch(e => {
      NotificationManager.error({ boldText: 'Forbidden', text: e.response.data.error })
    })
  }

  const getAliExpressProducts = () => {
    api.apiSearch.searchAliExpressProducts({ id: search.id, query: { page: aliExpressPage, sortBy: aliExpressSortBy, filters: aliExpressFilters } }).then(data => {
      dispatch(setAliExpressProducts(data.search.products))
      dispatch(setAliExpressPagination(data.search.pagination))
    }).catch(e => {
      NotificationManager.error({ boldText: 'Forbidden', text: e.response.data.error })
    })
  }

  const getTikTokPosts = () => {
    api.apiSearch.searchTikTokPosts({ id: search.id }).then(data => {
      setTikTokPosts(data.posts)
    }).catch(e => {
      NotificationManager.error({ boldText: 'Forbidden', text: e.response.data.error })
    })
  }

  useEffect(() => {
    getTikTokPosts()
  }, [])

  useEffect(() => {
    getProducts()
  }, [amazonPage, amazonSortBy, amazonFilters])

  useEffect(() => {
    getAliExpressProducts()
  }, [aliExpressPage, aliExpressSortBy, aliExpressFilters])

  useEffect(() => {
    const subscription = consumer.subscriptions.create({ channel: 'SearchChannel', id: search.id }, {
      received(message) {
        const data = deepConvertKeys(message, camelCase)
        console.log('received data', data)
        switch (data.event) {
          case 'success':
            setProgress(prev => ({
              ...prev,
              loading: false
            }))
            props.updateIsSearching(false);
            break
          case 'product':
            if (data.productType == "AmazonProduct" && data.product.hasSalesEstimation) {
              dispatch(appendAmazonProduct(data.product))

            }
            else if (data.productType == "AliExpressProduct") {
              dispatch(appendAliExpressProduct(data.product))
            }

            break
          case 'products': {
            dispatch(setAmazonProducts([...amazonProducts, ...data.products]))

            break
          }
          case 'tik_tok_post': {
            setTikTokPosts((prevTikTokPosts) => [...prevTikTokPosts, data.tikTokPost]);
          }
          case 'trends': {
            setTrends(data.trends)

            break
          }
          case 'notification':
            NotificationManager.message({text: data.message, time: 10000})
            break
          default: break
        }
      },
      search(params = {}) {
        this.perform('search', params)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [props.search.id])

  const showFilters = () => {
    setFiltersOpen(true)
  }

  const onFiltersChange = value => {
    if(activeTab === "amazonProducts") {
      dispatch(setAmazonFilters(value))
    }
    else if(activeTab === "aliExpressProducts") {
      dispatch(setAliExpressFilters(value))
    }
  }

  const onFiltersCancel = () => {
    if(activeTab === "amazonProducts") {
      dispatch(setAmazonFilters(null))
    }
    else if(activeTab === "aliExpressProducts") {
      dispatch(setAliExpressFilters(null))
    }
    setFiltersOpen(false)
  }

  const onSubmitToSwipe = (swipeId, successFn) => {
    const productIds = activeTab === "tiktok" ? [selectedPost] : activeTab === "amazonProducts" ? (selectedAmazonProduct ? [selectedAmazonProduct] : selectedAmazonProducts) : (selectedAliExpressProduct ? [selectedAliExpressProduct] : selectedAliExpressProducts)

    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: activeTab === "tiktok" ? 'TikTokPost' : activeTab === "amazonProducts" ? 'AmazonProduct' : 'AliExpressProduct',
      product_ids: productIds
    } }).then(() => {
      if (typeof successFn === 'function') successFn()
      onModalClose()
      NotificationManager.success({ boldText: `${productIds.length} product`, text: 'have been successfully added to swipe' })
      if(activeTab === "amazonProducts") {
        dispatch(setAmazonProducts(amazonProducts.map(p => productIds.includes(p.asin) ? { ...p, swipesIds: [...p.swipesIds, swipeId] } : p)))
      }
      else if(activeTab === 'aliExpressProducts') {
        dispatch(setAliExpressProducts(aliExpressProducts.map(p => productIds.includes(p.productId) ? { ...p, swipesIds: [...p.swipesIds, swipeId] } : p)))
      }
      else {
        setTikTokPosts((prevTikTokPosts) => prevTikTokPosts.map(p => p.id === selectedPost ? {...p, swipesIds: [...p.swipesIds, swipeId]} : p));
        setSelectedPost(null)
      }
    })
  }

  const onBulkSubmit = () => {
    activeTab === "amazonProducts" ? dispatch(setAmazonModalOpen()) : dispatch(setAliExpressModalOpen())
  }

  const onBulkHide = () => {
    api.apiSearch.hideProducts({ query: {
      id: search.id,
      product_ids: activeTab === "amazonProducts" ? selectedAmazonProducts : selectedAliExpressProducts,
      product_type: activeTab === "amazonProducts" ? "AmazonProduct" : "AliExpressProduct"
    } }).then(() => {
      onModalClose()
      if (activeTab === "amazonProducts") {
        dispatch(setAmazonProducts(amazonProducts.map(p => selectedAmazonProducts.includes(p.asin) ? { ...p, hidden: true } : p)))
      }
      else {
        dispatch(setAliExpressProducts(aliExpressProducts.map(p => selectedAliExpressProducts.includes(p.productId) ? { ...p, hidden: true } : p)))
      }
      NotificationManager.success({ boldText: `${(activeTab === "amazonProducts" ? selectedAmazonProducts : selectedAliExpressProducts).length} product`, text: 'have been successfully hidden' })
    })
  }

  const onBulkCancel = () => {
    activeTab === "amazonProducts" ? dispatch(setSelectedAmazonProducts([])) : dispatch(setSelectedAliExpressProducts([]))
  }

  const onPostSelect = (id) => {
    setSelectedPost(id)
  }

  const tabComponents = () => {
    switch(activeTab){
    case 'amazonProducts':
      return (
        <AmazonSearchProduct showFilters={showFilters} progress={progress} search={search}/>
      )
    case 'aliExpressProducts':
      return (
        <AliExpressSearchProduct showFilters={showFilters} progress={progress} search={search}/>
      )
    case 'tiktok':
      return (
        <TikTokPostSearch posts={tikTokPosts} onPostSelect={onPostSelect} />
      )
    case 'trends':
      return trends?.trendsChartData && (
        <div className="w-full flex mt-8 min-h-[400px]">
          <SeasonalityIndicator data={trends.trendsChartData} />
        </div>
      )
    case 'keywords':
      return trends?.relatedSearches && (
        <div className="w-full flex flex-col min-h-[300px]">
          <Keywords relatedSearches={trends.relatedSearches} />
        </div>
      )
    }
  }

  return (
    <>
      <div className="w-full flex mt-6">
        <div className="w-auto flex-1 flex items-center h-24">
          <div className={`flex-0 text-[32px] leading-9 font-medium ${progress.loading ? 'animate-pulse' : ''}`}>{progress.loading ? "Loading " : ""}Results for "{search.query}"</div>
        </div>
      </div>
      <div className="flex mt-4">
        <Tabs options={tabsOptions} tab={activeTab} onChange={setActiveTab} />
      </div>
      { tabComponents() }
      {progress.loading &&
        <div className="relative w-full overflow-x-auto mt-4"></div>
      }
      <Drawer isOpen={isFiltersOpen} setIsOpen={setFiltersOpen}>
        {
          activeTab === "amazonProducts"
          ? <Filters filters={amazonFilters} onChange={onFiltersChange} onCancel={onFiltersCancel} onClose={() => setFiltersOpen(false)} />
          : <AliExpressFilters filters={aliExpressFilters} onChange={onFiltersChange} onCancel={onFiltersCancel} onClose={() => setFiltersOpen(false)} />
        }
      </Drawer>
      {
        activeTab === "tiktok" && selectedPost && (
          <AddToListing onSubmit={onSubmitToSwipe} onClose={() => setSelectedPost(null)} selectedIds={[selectedPost]} />
        )
      }
      {isAmazonModalOpen || isAliExpressModalOpen ? (
        <AddToListing onSubmit={onSubmitToSwipe} onClose={onModalClose}
          selectedIds={
            isAmazonModalOpen
              ? selectedAmazonProduct ? [selectedAmazonProduct] : selectedAmazonProducts
              : selectedAliExpressProduct ? [selectedAliExpressProduct] : selectedAliExpressProducts
          }
        />
      ) : (
        <BulkActions
          isOpen={activeTab === "amazonProducts" ? selectedAmazonProducts.length : selectedAliExpressProducts.length}
          selectedIds={activeTab === "amazonProducts" ? selectedAmazonProducts : selectedAliExpressProducts}
          onAddToSwipe={onBulkSubmit}
          onCancel={onBulkCancel}
          onHide={onBulkHide}
        />
      )}
    </>
  )
}

export default React.memo(Research)
