// JsFromRoutes CacheKey b915d7437f3c3b094384e9c251fda86f
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  products: definePathHelper('get', '/public/swipes/products'),
  aliExpressProducts: definePathHelper('get', '/public/swipes/ali_express_products'),
  tikTokPosts: definePathHelper('get', '/public/swipes/tik_tok_posts'),
  ads: definePathHelper('get', '/public/swipes/ads'),
  trending: definePathHelper('get', '/public/swipes/trending'),
  advertisers: definePathHelper('get', '/public/swipes/advertisers'),
  adProducts: definePathHelper('get', '/public/swipes/ad_products'),
  get: definePathHelper('get', '/public/swipes/:id'),
}
