import { nFormatter } from '~/helpers/formatter'
import s from './Table.module.sass'

const Table = props => {

  return (
    <table className={s.Table}>
      <thead>
        <tr>
          <th>Keywords</th>
          <th>Searched Count</th>
        </tr>
      </thead>
      <tbody>
        {
          props.relatedSearches.map((search, index) => (
            <tr key={index}>
              <td>
                <div className="ml-4 font-medium text-[16px]">{ search.query }</div>
              </td>
              <td>{ nFormatter(search.value) }</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default Table
