import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Drawer from '~/components/layout/Drawer'

const ResearchProduct = ({ children }) => {
  const [, setSearchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const toggleOpen = val => {
    console.log('val', val)
    setIsOpen(false)
    setTimeout(() => {
      setSearchParams({})
    }, 250)
  }

  return (
    <Drawer isOpen={isOpen} setIsOpen={toggleOpen}>
      { children }
    </Drawer>
  )
}

export default ResearchProduct
