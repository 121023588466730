import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProduct, setSelectedProducts, setSortBy, setPage, setModalOpen, setModalClose } from '~/store/reducers/amazonSearchProductsSlice';
import api from '~/api'
import { CSVLink } from 'react-csv'
import { useSearchParams } from 'react-router-dom'
import ResearchProduct from '~/pages/ResearchProduct'
import NotificationManager from '~/services/NotificationManager'
import Products from '~/components/Products'
import { FakeProduct } from '~/components/Product'
import Button from '~/components/layout/Button'
import { NumberFormatter, USDFormatter } from './Research';
import ResearchProductContainer from '~/components/ResearchProduct'

function AmazonSearchProduct({showFilters, progress, search}) {
  const products = useSelector(state => state.amazonSearchProducts.products);
  const pagination = useSelector(state => state.amazonSearchProducts.pagination);
  const sortBy = useSelector(state => state.amazonSearchProducts.sortBy);
  const selectedProducts = useSelector(state => state.amazonSearchProducts.selectedProducts);
  const filters = useSelector(state => state.amazonSearchProducts.filters);
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportProducts, setExportProducts] = useState([]);
  const csvLink = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams()
  const productAsin = searchParams.get('asin')
  const product = products.find(p => p.asin === productAsin)
  const dispatch = useDispatch()

  const getAllProducts = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!loadingExport) {
      setLoadingExport(true);
      api.apiSearch.searchAmazonProducts({ id: search.id, query: { items: pagination?.count || 100, sortBy, filters } }).then(data => {
        setExportProducts(data.search.products)
        setLoadingExport(false);
      }).catch(e => {
        NotificationManager.error({ boldText: 'Forbidden', text: e.response.data.error })
        setLoadingExport(false);
      })
    }
  }

  useEffect(()=> {
    if(exportProducts.length > 0 && loadingExport === false){
      csvLink.current.link.click();
      setExportProducts([])
    }
  }, [exportProducts])

  const onPageChange = (e) => {
    let pageNumber = e.selected + 1
    dispatch(setPage(pageNumber))
  }

  const onSort = (value) => {
    dispatch(setSortBy(value))
  }

  const onProductAdd = id => {
    dispatch(setSelectedProduct(id))
    dispatch(setModalOpen())
  }

  const onSelectionChange = ids => {
    dispatch(setSelectedProducts(ids))
  }

  const onProductClick = useCallback(id => {
    setSearchParams({ asin: id })
  }, [])

  const fakeProducts = progress.loading ? [...Array(10 - Math.min(products.length, 10)).keys()] : []

  const exportData = exportProducts.map(p => ({
                      Asin: p.asin,
                      Title: p.title,
                      URL: p.url,
                      Price: USDFormatter.format(p.price / 100),
                      'Sales P/M (Last 30 days)': p.salesEstimation.monthlySalesEstimate,
                      'Revenue P/M (Last 30 days)': USDFormatter.format(p.salesEstimation.monthlySalesEstimate * p.price / 100),
                      BSR: p.bestseller_rank,
                      'Gross Profit': USDFormatter.format(p.grossProfit / 100),
                      'Seller Type': p.sellerType,
                      Seasonality: 'N/A',
                      'Review Score': p.reviewScore,
                      'Review Count': p.reviewCount
                    }))
  return (
    <>
      <div className="relative w-full overflow-x-auto mt-7">
        <div className="w-auto flex flex-1 justify-between items-center">
          <div className="text-gray text-lg">Found { NumberFormatter.format(pagination?.count || products.length) } results</div>
          <div className="flex">
            <div className="mr-4"><Button secondary noShadow onClick={showFilters}>Filter By</Button></div>
            <div>
              <Button secondary noShadow onClick={getAllProducts}>
                  {loadingExport ? 'Loading products...' : 'Export'}
              </Button>
              <CSVLink data={exportData} filename="export.csv" ref={csvLink} />
            </div>
          </div>
        </div>
        {
          products.length > 0 && <Products
          data={products.filter(p => !p.hidden)}
          pagination={pagination}
          sortBy={sortBy}
          onPageChange={onPageChange}
          onSort={onSort}
          selectedIds={selectedProducts}
          filters={filters}
          onAdd={onProductAdd}
          onSelectionChange={onSelectionChange}
          onProductClick={onProductClick}
          />
        }
        {
          !!fakeProducts && fakeProducts.map(p => (
            <FakeProduct key={p} />
          ))
        }
      </div>
      { product && <ResearchProduct><ResearchProductContainer product={product} /></ResearchProduct> }
    </>
  )
}

export default AmazonSearchProduct
