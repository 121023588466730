import React from 'react'
import ReactPaginate from 'react-paginate'

const AdsList = props => {
  const loadingStyles = { opacity: props.loading ? 0.5 : 1 }

  return (
    <>
      <div style={loadingStyles} className="w-full grid -mt-1 lg:gap-6 xl:grid-cols-4 md:gap-4 lg:grid-cols-3 gap-2 grid-cols-2">
        { props.children }
      </div>
      <div style={loadingStyles} className="w-full">
        {props.pagesCount > 1 &&
          <ReactPaginate
            onPageChange={props.onPageChange}
            pageRangeDisplayed={5}
            pageCount={props.pagesCount}
            renderOnZeroPageCount={null}
            className="pagination justify-end mt-4"
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            forcePage={props.currentPage - 1}
          />
        }
      </div>
    </>
  )
}

export default React.memo(AdsList)
