// JsFromRoutes CacheKey 9081d5315542ec1399b30082152dc61d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/swipe_groups'),
  create: definePathHelper('post', '/api/swipe_groups'),
  new: definePathHelper('get', '/api/swipe_groups/new'),
  edit: definePathHelper('get', '/api/swipe_groups/:id/edit'),
  get: definePathHelper('get', '/api/swipe_groups/:id'),
  update: definePathHelper('patch', '/api/swipe_groups/:id'),
  destroy: definePathHelper('delete', '/api/swipe_groups/:id'),
}
