import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProduct, setSelectedProducts, setSortBy, setPage, setModalOpen, setModalClose } from '~/store/reducers/aliExpressSearchProductsSlice';
import api from '~/api'
import { CSVLink } from 'react-csv'
import { useSearchParams } from 'react-router-dom'
import ResearchProduct from '~/pages/ResearchProduct'
import AliExpressResearchProduct from '~/components/ResearchProduct/AliExpressResearchProduct'
import NotificationManager from '~/services/NotificationManager'
import { FakeProduct } from '~/components/Product'
import Button from '~/components/layout/Button'
import { NumberFormatter, USDFormatter } from './Research';
import ReactPaginate from 'react-paginate'
import Checkbox from '~/components/layout/Checkbox'
import SortTopIcon from '~/components/icons/SortTop'
import Rating from '~/components/layout/Rating'
const SortColumn = props => (
  <div className="flex-1 flex items-center justify-center" onClick={props.onSort}>
    <div className="flex cursor-pointer select-none p-1">
      <span>{ props.title }</span>
      <div className="ml-1"><SortTopIcon /></div>
    </div>
  </div>
)

function AliExpressSearchProduct({showFilters, progress, search}) {
  const products = useSelector(state => state.aliExpressSearchProducts.products);
  const pagination = useSelector(state => state.aliExpressSearchProducts.pagination);
  const sortBy = useSelector(state => state.aliExpressSearchProducts.sortBy);
  const selectedProducts = useSelector(state => state.aliExpressSearchProducts.selectedProducts);
  const filters = useSelector(state => state.aliExpressSearchProducts.filters);
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportProducts, setExportProducts] = useState([]);
  const csvLink = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams()
  const productId = searchParams.get('productId')
  const product = products.find(p => p.productId === productId)
  const dispatch = useDispatch()

  const getAllProducts = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!loadingExport) {
      setLoadingExport(true);
      api.apiSearch.searchAliExpressProducts({ id: search.id, query: { items: pagination?.count || 100, sortBy, filters } }).then(data => {
        setExportProducts(data.search.products)
        setLoadingExport(false);
      }).catch(e => {
        NotificationManager.error({ boldText: 'Forbidden', text: e.response.data.error })
        setLoadingExport(false);
      })
    }
  }

  useEffect(()=> {
    if(exportProducts.length > 0 && loadingExport === false){
      csvLink.current.link.click();
      setExportProducts([])
    }
  }, [exportProducts])

  const onPageChange = (e) => {
    let pageNumber = e.selected + 1
    dispatch(setPage(pageNumber))
  }

  const onSort = (value) => {
    dispatch(setSortBy(value))
  }

  const onProductAdd = id => {
    dispatch(setSelectedProduct(id))
    dispatch(setModalOpen())
  }

  const onSelectionChange = ids => {
    dispatch(setSelectedProducts(ids))
  }

  const onProductClick = useCallback(id => {
    setSearchParams({ productId: id })
  }, [])

  const fakeProducts = progress.loading ? [...Array(10 - Math.min(products.length, 10)).keys()] : []

  const exportData = exportProducts.map(p => ({
                      ID: p.productId,
                      Title: p.title,
                      Description: p.description,
                      Link: p.url,
                      'Seller Type': p.sellerType,
                      Price: p.priceCents,
                      Shipping: p.shippingCost,
                      Total: p.totalCost,
                      'Orders Count': p.ordersCount,
                      'Review Count': p.reviewCount,
                      'Review Score': p.reviewScore
                    }))
  return (
    <>
      <div className="relative w-full overflow-x-auto mt-7">
        <div className="w-auto flex flex-1 justify-between items-center">
          <div className="text-gray text-lg">Found { NumberFormatter.format(pagination?.count || products.length) } results</div>
          <div className="flex">
            <div className="mr-4"><Button secondary noShadow onClick={showFilters}>Filter By</Button></div>
            <div>
              <Button secondary noShadow onClick={getAllProducts}>
                  {loadingExport ? 'Loading products...' : 'Export'}
              </Button>
              <CSVLink data={exportData} filename="export.csv" ref={csvLink} />
            </div>
          </div>
        </div>
        {
          products.length > 0 && <Products
          data={products.filter(p => !p.hidden)}
          pagination={pagination}
          sortBy={sortBy}
          onPageChange={onPageChange}
          onSort={onSort}
          selectedIds={selectedProducts}
          filters={filters}
          onAdd={onProductAdd}
          onSelectionChange={onSelectionChange}
          onProductClick={onProductClick}
          />
        }
        {
          !!fakeProducts && fakeProducts.map(p => (
            <FakeProduct key={p} />
          ))
        }
      </div>
      { product && <ResearchProduct><AliExpressResearchProduct product={product} /></ResearchProduct> }
    </>
  )
}
export const Products = props => {
  const paginatedProducts = props.data

  const toggleProductSelect = id => {
    const newSelectionIds = props.selectedIds.includes(id) ? props.selectedIds.filter(el => el !== id) : [...props.selectedIds, id]
    props.onSelectionChange?.(newSelectionIds)
  }

  const paginatedProductsLength = paginatedProducts.length
  const currentPageSelected = props.selectedIds.filter(productId => paginatedProducts.find(p => p.productId === productId))
  const currentPageSelectedLength = currentPageSelected.length

  const isChecked = (() => {
    if (currentPageSelected.length < paginatedProductsLength) return false
    if (!currentPageSelected.length) return false

    return currentPageSelected.every(productId => paginatedProducts.find(p => p.productId === productId))
  })()

  const isIndeterminate = !isChecked && currentPageSelectedLength > 0 && currentPageSelectedLength < paginatedProductsLength

  const toggleAll = () => {
    if (isIndeterminate) {
      const newIds = props.selectedIds.filter(productId => !paginatedProducts.find(p => p.productId === productId))
      props.onSelectionChange?.(newIds)
    } else {
      props.onSelectionChange?.([...props.selectedIds, ...paginatedProducts.map(p => p.productId)])
    }
  }

  const onSort = column => {
    props.onSort([column, props.sortBy[0] === column ? (props.sortBy[1] === 'asc' ? 'desc' : 'asc') : 'desc'])
  }

  return (
    <div className="mt-7">
      <div className="flex flex-col">
        <div className="flex min-h-[2.5rem] bg-white text-gray text-[10px] uppercase tracking-wider rounded-t-lg">
          <div className="flex basis-[50px] items-center justify-center">
            <div className="flex items-center">
              <Checkbox checked={isChecked} onChange={toggleAll} indeterminate={isIndeterminate} />
            </div>
          </div>
          <div className="flex items-center 2xl:basis-[530px] xl:basis-[450px] basis-[380px]  2xl:max-w-[530px] xl:max-w-[450px] max-w-[380px]">Product</div>
          <SortColumn title="Price" onSort={onSort.bind(this, 'price_cents')} />
          <SortColumn title="EST. Sales" onSort={onSort.bind(this, 'orders_count')} />
          <SortColumn title="Shipping Cost" onSort={onSort.bind(this, 'shipping_cost')} />
          {/* <SortColumn title="BSR" onSort={onSort.bind(this, 'bestseller_rank')} /> */}
          {/* <SortColumn title="Gross Profit" onSort={onSort.bind(this, 'price')} /> */}
          <SortColumn title="Total Cost" onSort={onSort.bind(this, 'total_cost')}/>
          <SortColumn title="Reviews and ratings" onSort={onSort.bind(this, 'review_rating')} />
          { typeof props.onAdd === 'function' && <div className="flex basis-[100px] items-center justify-center"></div> }
        </div>
        {
          paginatedProducts.map(product => (
            <Product
              key={product.productId}
              product={product}
              onAdd={props.onAdd}
              checked={props.selectedIds.includes(product.productId)}
              toggleSelect={toggleProductSelect}
              onClick={props.onProductClick}
            />
          ))
        }
      </div>
      {props.pagination && props.pagination.pages > 1 &&
        <ReactPaginate
          onPageChange={props.onPageChange}
          pageRangeDisplayed={5}
          pageCount={props.pagination.pages}
          renderOnZeroPageCount={null}
          className="pagination justify-end mt-4"
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={props.pagination.page - 1}
        />
      }
    </div>
  )
}


const Product = props => {
  const { productId, title, url, image, priceCents, ordersCount, revenue, reviewCount, reviewScore, swipesIds, sellerType, totalCost, shippingCost } = props.product

  const onProductClick = () => {
    props.onClick?.(productId)
  }

  const onAdd = e => {
    e.stopPropagation()
    props.onAdd(productId)
  }

  const toggleSelect = () => {
    props.toggleSelect(productId)
  }

  return (
    <div key={productId} onClick={onProductClick} className="flex h-[92px] bg-white text-sm shadow-sm cursor-pointer transition-colors border-t border-gray2 last:rounded-b-lg">
      <div className="flex basis-[50px] items-center justify-center">
        <div className="flex items-center">
          <Checkbox onClick={e => e.stopPropagation()} checked={props.checked} onChange={toggleSelect} />
        </div>
      </div>
      <div className=" 2xl:basis-[530px] xl:basis-[450px] basis-[380px]  2xl:max-w-[530px] xl:max-w-[450px] max-w-[380px] flex items-center">
        <div className="flex-none w-[70px] h-[70px] bg-contain bg-no-repeat bg-center"  style={{ backgroundImage: `url("${image}")` }} />
        <div className="flex flex-col truncate ml-4">
          <div className="items-center truncate 2xl:text-base text-sm">
            { title }
          </div>
          <div className="items-center truncate font-medium text-xs">
            <a className="text-blue" href={url} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>{ productId }</a>
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">{ priceCents?.[0] > 0 ? USDFormatter.format(priceCents?.[0]) : "N/A" }</div>
      <div className="flex-1 flex items-center justify-center">{ ordersCount > 0 ? ordersCount : "N/A" }</div>
      {/* <div className="flex-1 flex items-center justify-center">{ revenue > 0 ? USDFormatter.format(revenue) : "N/A" }</div> */}
      {/* <div className="flex-1 flex items-center justify-center">{ bestsellerRank }</div> */}
      {/* <div className="flex-1 flex items-center justify-center">{ USDFormatter.format(grossProfit / 100) }</div> */}
      <div className="flex-1 flex items-center justify-center">{ shippingCost > 0 ? USDFormatter.format(shippingCost) : "Free" }</div>
      <div className="flex-1 flex items-center justify-center">{ totalCost > 0 ? USDFormatter.format(totalCost) : "N/A"}</div>
      {
        reviewCount > 0
        ? <div className="flex-1 flex-col flex items-end justify-center">
          <div className="flex flex-col items-center justify-center text-secondary text-xs">
            { NumberFormatter.format(reviewCount) }
          </div>
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center mr-2 text-sm font-medium">
              { reviewScore }
            </div>
            <Rating score={reviewScore} />
          </div>
        </div>
        : <div className="flex-1 flex-col flex items-center justify-center">N/A</div>
      }
      {
        props.onAdd
        ? <div className="flex basis-[100px] items-center justify-center">
          <Button green={swipesIds?.length} onClick={onAdd}>{ swipesIds?.length ? 'Added' : 'Add' }</Button>
        </div>
        : <div className="w-5"></div>
      }
    </div>
  )
}

export default AliExpressSearchProduct
