import { createSlice } from '@reduxjs/toolkit';

const amazonSearchProductsSlice = createSlice({
  name: 'amazonSearchProducts',
  initialState: {
    products: [],
    selectedProduct: null,
    selectedProducts: [],
    filters: null,
    sortBy: ["monthly_revenue", "desc"],
    page: 1,
    pagination: null,
    swipeModalOpen: false
  },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    appendProduct: (state, action) => {
      state.products = [...state.products, action.payload]
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
      state.selectedProducts = [];
    },
    setSelectedProducts: (state, action) => {
      state.selectedProducts = action.payload;
    },
    setFilters: (state, action) => {
      state.filters =  action.payload;
      state.page = 1;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
      state.page = 1;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    onProductSelect: (state, action) => {
      state.selectedProduct = action.payload;
      state.swipeModalOpen = true;
    },
    setModalClose: (state) => {
      state.swipeModalOpen = false;
      state.selectedProduct = null;
      state.selectedProducts = [];
    },
    setModalOpen: (state) => {
      state.swipeModalOpen = true;
    }
  },
});

export const { setProducts, appendProduct, setSelectedProduct, setSelectedProducts, setFilters, setSortBy, setPage, setPagination, onProductSelect, setModalClose, setModalOpen } = amazonSearchProductsSlice.actions;

export default amazonSearchProductsSlice.reducer;
