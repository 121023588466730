const Tabs = props => {
  const onTabClick = tab => {
    props.onChange(tab)
  }

  return (
    <div className="flex bg-[#ECEFF3] rounded-[55px] p-0.5 text-sm">
      {
        props.options.map(option => (
          <div key={option.value} className={`flex items-center px-[17px] py-[10px] rounded-[40px] cursor-pointer ${props.tab === option.value ? 'bg-white' : 'bg-none text-[#49546D]'}`} onClick={onTabClick.bind(this, option.value)}>
            <div className="h-full flex items-center justify-center">{ option.label }</div>
            { option.extra && <span className="ml-2">{ option.extra }</span> }
          </div>
        ))
      }
    </div>
  )
}

export default Tabs
