import { subMonths, subYears, startOfMonth, endOfMonth } from 'date-fns';

export const customStaticRanges = [
  {
    label: 'Past Month',
    range: () => {
      const endDate = new Date();
      const startDate = subMonths(endDate, 1);
      return {
        startDate: startOfMonth(startDate),
        endDate: endOfMonth(startDate),
      };
    },
    isSelected: (range) => {
      const endDate = new Date();
      const startDate = subMonths(endDate, 1);
      return (
        range.startDate.getTime() === startOfMonth(startDate).getTime() &&
        range.endDate.getTime() === endOfMonth(startDate).getTime()
      );
    },
  },
  {
    label: 'Past 3 Months',
    range: () => {
      const endDate = new Date();
      const startDate = subMonths(endDate, 3);
      return {
        startDate: startOfMonth(startDate),
        endDate: endOfMonth(endDate),
      };
    },
    isSelected: (range) => {
      const endDate = new Date();
      const startDate = subMonths(endDate, 3);
      return (
        range.startDate.getTime() === startOfMonth(startDate).getTime() &&
        range.endDate.getTime() === endOfMonth(endDate).getTime()
      );
    },
  },
  {
    label: 'Past 9 Months',
    range: () => {
      const endDate = new Date();
      const startDate = subMonths(endDate, 9);
      return {
        startDate: startOfMonth(startDate),
        endDate: endOfMonth(endDate),
      };
    },
    isSelected: (range) => {
      const endDate = new Date();
      const startDate = subMonths(endDate, 9);
      return (
        range.startDate.getTime() === startOfMonth(startDate).getTime() &&
        range.endDate.getTime() === endOfMonth(endDate).getTime()
      );
    },
  },
  {
    label: 'Past Year',
    range: () => {
      const endDate = new Date();
      const startDate = subYears(endDate, 1);
      return {
        startDate: startOfMonth(startDate),
        endDate: endOfMonth(endDate),
      };
    },
    isSelected: (range) => {
      const endDate = new Date();
      const startDate = subYears(endDate, 1);
      return (
        range.startDate.getTime() === startOfMonth(startDate).getTime() &&
        range.endDate.getTime() === endOfMonth(endDate).getTime()
      );
    },
  },
  {
    label: 'Past 2 Years',
    range: () => {
      const endDate = new Date();
      const startDate = subYears(endDate, 2);
      return {
        startDate: startOfMonth(startDate),
        endDate: endOfMonth(endDate),
      };
    },
    isSelected: (range) => {
      const endDate = new Date();
      const startDate = subYears(endDate, 2);
      return (
        range.startDate.getTime() === startOfMonth(startDate).getTime() &&
        range.endDate.getTime() === endOfMonth(endDate).getTime()
      );
    },
  },
  {
    label: 'Past 3 Years',
    range: () => {
      const endDate = new Date();
      const startDate = subYears(endDate, 3);
      return {
        startDate: startOfMonth(startDate),
        endDate: endOfMonth(endDate),
      };
    },
    isSelected: (range) => {
      const endDate = new Date();
      const startDate = subYears(endDate, 3);
      return (
        range.startDate.getTime() === startOfMonth(startDate).getTime() &&
        range.endDate.getTime() === endOfMonth(endDate).getTime()
      );
    },
  }
]