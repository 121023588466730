import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Rating from '~/components/layout/Rating'
import Gallery from './Gallery'
import ArrowLeft from '~/components/icons/ArrowLeft'
import FavoriteIcon from '~/components/icons/FavoriteIcon'
import ExternalLinkIcon from '~/components/icons/ExternalLinkIcon'
import { formatCurrency } from '../Research/RecentSearch'
import { nFormatter } from '~/helpers/formatter'
import { useDispatch } from 'react-redux';
import { setSelectedProduct, setModalOpen } from '~/store/reducers/amazonSearchProductsSlice';
dayjs.extend(relativeTime)

const USDFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const ResearchProduct = React.memo(props => {
  console.log('product', props.product)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { images } = props.product.raw.productDetails

  const onBack = () => {
    navigate(location.pathname)
  }

  const onAddToSwipe = () => {
    dispatch(setSelectedProduct(props.product.asin))
    dispatch(setModalOpen(props.product.asin))
  }

  return (
    <div className="flex flex-col h-full py-5">
      <div className="flex items-center px-4 border-b border-gray2 pb-5">
        <div className="mr-4 cursor-pointer" onClick={onBack}><ArrowLeft /></div>
        <div className="flex flex-auto flex-col">
          <div className="flex text-xl leading-6">{ props.product.title }</div>
          <div className="flex justify-between mt-2.5">
            <div className="">
              <div className="flex">
                <Rating score={props.product.reviewScore} />
                <div className="ml-2 flex text-sm">{ props.product.reviewScore }</div>
              </div>
            </div>
            <div className="flex text-xs text-secondary">
              Added { dayjs(props.product.createdAt).fromNow() }
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-6 px-4">
        <Gallery images={images} />
      </div>
      <div className="flex mt-5 px-4">
        <div className="flex flex-1 py-4 bg-white rounded-lg shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
          <div className="flex flex-col flex-1 px-4 border-r border-[rgba(159,166,183,0.2)]">
            <div className="text-[10px] uppercase font-medium text-gray">
              Price
            </div>
            <div className="mt-2 text-lg font-medium leading-5">
              { USDFormatter.format(props.product.price / 100) }
            </div>
          </div>
          <div className="flex flex-col flex-1 px-4 border-r border-[rgba(159,166,183,0.2)]">
            <div className="text-[10px] uppercase font-medium text-gray">
              Monthly Revenue
            </div>
            <div className="mt-2 text-lg font-medium leading-5">
            { props.product.monthlyRevenue ? formatCurrency(props.product.monthlyRevenue/100) : 'N/A' }
            </div>
          </div>
          <div className="flex flex-1 px-4 items-center">
            <div className="flex flex-1 flex-col">
              <div className="text-[10px] uppercase font-medium text-gray">
                Sales
              </div>
              <div className="mt-2 text-lg font-medium leading-5">
              { props.product.monthlySales ? nFormatter(props.product.monthlySales) :'N/A' }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex py-5 flex-1 items-end">
        <div className="flex flex-1 items-center cursor-pointer pl-12" onClick={onAddToSwipe}>
          <FavoriteIcon />
          <span className="ml-2 text-sm select-none">Add to my favorites</span>
        </div>
        <div className="flex flex-1 items-center cursor-pointer pl-12 border-l border-[rgba(159,166,183,0.3)]">
          <ExternalLinkIcon />
          <a href={props.product.url} target="_blank" rel="noreferrer" className="ml-2 text-sm select-none">See on Amazon</a>
        </div>
      </div>
    </div>
  )
})
ResearchProduct.displayName = 'ResearchProduct'

export { ResearchProduct }
