import React, { useCallback } from 'react'

const Input = props => {
  const classNames = ['w-full px-[16px] rounded-lg text-sm outline-none']
  classNames.push(props.large ? 'h-12' : 'h-9')
  // if (props.secondary) classNames.push('secondary')
  // if (props.large) classNames.push('large')
  // if (props.full) classNames.push('w-full')

  const ref = useCallback(inputElement => {
    if (inputElement && props.autoFocus) {
      inputElement.focus()
    }
  }, [])

  return (
    <input
      ref={ref}
      type={props.type || 'text'}
      onChange={props.onChange}
      value={props.value || ''}
      className={props.className || classNames.join(' ')}
      style={props.style}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete}
      maxLength={props.maxLength}
      disabled={props.disabled || false}
    />
  )
}

export default React.memo(Input)
