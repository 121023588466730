// JsFromRoutes CacheKey dbb04e2e0caa31dfb235ee17ef70793d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/ads_filters'),
  create: definePathHelper('post', '/api/ads_filters'),
  destroy: definePathHelper('delete', '/api/ads_filters/:id'),
}
