// JsFromRoutes CacheKey a285b66da9636159921f6ac39dd90c13
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/ali_express_products'),
  create: definePathHelper('post', '/api/ali_express_products'),
  new: definePathHelper('get', '/api/ali_express_products/new'),
  edit: definePathHelper('get', '/api/ali_express_products/:id/edit'),
  get: definePathHelper('get', '/api/ali_express_products/:id'),
  update: definePathHelper('patch', '/api/ali_express_products/:id'),
  destroy: definePathHelper('delete', '/api/ali_express_products/:id'),
}
