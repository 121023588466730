// JsFromRoutes CacheKey f64ad9527f42d5d2a343981ba1ee92b2
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: definePathHelper('post', '/api/trending/:trending_id/videos'),
  list: definePathHelper('get', '/api/trending_videos'),
  destroy: definePathHelper('delete', '/api/trending_videos/:id'),
}
