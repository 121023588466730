import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import ProgressiveImage from 'react-progressive-graceful-image'
import TrendingFavoriteIcon from '~/components/icons/TrendingFavorite'
import GrowthChart from './GrowthChart'

dayjs.extend(relativeTime)

const Card = props => {
  const handleClick = () => {
    props.onClick(props.productId)
  }

  const addToSwipe = e => {
    e.stopPropagation()

    props.addToSwipe(props.productId)
  }

  return (
    <div className="relative flex flex-col w-full mt-6 cursor-pointer rounded-lg bg-white shadow-[0_4px_50px_rgba(0,0,0,0.05)]" onClick={handleClick}>
      <div className="flex flex-col flex-auto w-full xl:p-5 lg:p-3 p-2">
        <div className="flex basis-[100px] justify-center min-w-[100px] h-[173px]">
          <ProgressiveImage src={props.imageSrc} placeholder="">
            {(src, loading) => (
              loading
              ? <div className="rounded-lg w-full h-[173px] bg-gray opacity-10" />
              : <img src={src} className="rounded-lg h-[173px]" />
            )}
          </ProgressiveImage>
        </div>
        <div className="flex flex-col w-full min-w-0 mt-4">
          <div className="flex text-[20px] leading-8 items-center">
            <div className="flex-auto truncate">{ props.title }</div>
            {typeof props.addToSwipe === 'function' &&
              <div className="flex-0 text-gray ml-2" onClick={addToSwipe}>
                <TrendingFavoriteIcon $active={props.isFavourite} />
              </div>
            }
          </div>
          <div className="flex w-full mt-1 text-xs text-gray leading-5">Updated { props.updatedAt }</div>
        </div>
      </div>
      {props.aliexpress &&
        <div className="flex flex-0 w-full py-[11px] xl:px-5 lg:px-3 px-2 border-t border-gray2 justify-between items-center">
          {props.growth >= 0 &&
            <GrowthChart value={props.growth} />
          }
          <div className="flex flex-col">
            <div className="text-xs">This month orders { props.currentOrdersCount }</div>
            { props.currentOrdersCount > props.ordersCount ? <div className="text-xs text-success">(was greater than { props.ordersCount })</div> : <div className="text-xs text-error">(was less than { props.ordersCount })</div>}
          </div>
        </div>
      }
    </div>
  )
}

export default Card
