const ModularDetails = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.3333 6.89102V3.52435C18.3333 2.19935 17.8 1.66602 16.475 1.66602H13.1083C11.7833 1.66602 11.25 2.19935 11.25 3.52435V6.89102C11.25 8.21602 11.7833 8.74935 13.1083 8.74935H16.475C17.8 8.74935 18.3333 8.21602 18.3333 6.89102Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.75008 7.09935V3.31602C8.75008 2.14102 8.21675 1.66602 6.89175 1.66602H3.52508C2.20008 1.66602 1.66675 2.14102 1.66675 3.31602V7.09102C1.66675 8.27435 2.20008 8.74102 3.52508 8.74102H6.89175C8.21675 8.74935 8.75008 8.27435 8.75008 7.09935Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.75008 16.475V13.1083C8.75008 11.7833 8.21675 11.25 6.89175 11.25H3.52508C2.20008 11.25 1.66675 11.7833 1.66675 13.1083V16.475C1.66675 17.8 2.20008 18.3333 3.52508 18.3333H6.89175C8.21675 18.3333 8.75008 17.8 8.75008 16.475Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 12.916H17.5" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
    <path d="M12.5 16.25H17.5" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
  </svg>
)

export default ModularDetails
