// const SI_SYMBOLS = ['', 'K', 'M', 'G', 'T', 'P', 'E']
const SI_SYMBOLS = ['', 'K', 'M', 'B', 'T']

export const nFormatter = (number, minDigits = 1, maxDigits = 1) => {
  if (!number || number === 0) return number

  const tier = Math.floor(Math.log10(Math.abs(number)) / 3)

  if (tier === 0) return number

  const suffix = SI_SYMBOLS[tier]
  const scale = 10 ** (tier * 3)

  const scaled = number / scale

  return scaled.toLocaleString(undefined, {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  }) + suffix
}

export const toPercentage = (val, total) => (total ? Math.round(val / total * 100) : 0)

export const currencyFormatter = (price, currencyCode) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode || 'USD',
  });

  return formatter.format(price);
}
