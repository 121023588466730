const BrandProfile = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5833 14.9993H3.45831C2.26665 14.9993 1.66663 14.3993 1.66663 13.2077V3.4577C1.66663 2.26604 2.26665 1.66602 3.45831 1.66602H7.04164C8.2333 1.66602 8.83327 2.26604 8.83327 3.4577V4.99935" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.475 7.01665V16.3167C14.475 17.6583 13.8084 18.3333 12.4667 18.3333H7.60003C6.25836 18.3333 5.58337 17.6583 5.58337 16.3167V7.01665C5.58337 5.67499 6.25836 5 7.60003 5H12.4667C13.8084 5 14.475 5.67499 14.475 7.01665Z" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.1667 4.99935V3.4577C11.1667 2.26604 11.7667 1.66602 12.9584 1.66602H16.5417C17.7334 1.66602 18.3334 2.26604 18.3334 3.4577V13.2077C18.3334 14.3993 17.7334 14.9993 16.5417 14.9993H14.4751" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.33337 9.16602H11.6667" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.33337 11.666H11.6667" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 18.334V15.834" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default BrandProfile
