// JsFromRoutes CacheKey 701c6c1f4527838d7a72522f9eeeb203
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  addAds: definePathHelper('post', '/api/briefs/:id/add_ads'),
  removeAd: definePathHelper('post', '/api/briefs/:id/remove_ad'),
  list: definePathHelper('get', '/api/briefs'),
  create: definePathHelper('post', '/api/briefs'),
  new: definePathHelper('get', '/api/briefs/new'),
  edit: definePathHelper('get', '/api/briefs/:id/edit'),
  get: definePathHelper('get', '/api/briefs/:id'),
  update: definePathHelper('patch', '/api/briefs/:id'),
  destroy: definePathHelper('delete', '/api/briefs/:id'),
}
