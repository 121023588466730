import { createNanoEvents } from 'nanoevents'

class NotificationManager {
  constructor() {
    this.emitter = createNanoEvents()
  }

  success(message) {
    this.notify('success', message)
  }

  error(message) {
    this.notify('error', message)
  }

  message(message) {
    this.notify('netural', message)
  }

  notify(type, message) {
    this.emitter.emit('notification', { type, message })
  }

  on(callbackFn) {
    this.emitter.on('notification', callbackFn)
  }
}

export default new NotificationManager()
