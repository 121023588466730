// JsFromRoutes CacheKey 569bc12bf4b6b1456ebc782e56731eaa
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/brief_scenes'),
  create: definePathHelper('post', '/api/brief_scenes'),
  new: definePathHelper('get', '/api/brief_scenes/new'),
  edit: definePathHelper('get', '/api/brief_scenes/:id/edit'),
  get: definePathHelper('get', '/api/brief_scenes/:id'),
  update: definePathHelper('patch', '/api/brief_scenes/:id'),
  destroy: definePathHelper('delete', '/api/brief_scenes/:id'),
}
