const Audience = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6.10666 7.24732C6.03999 7.24065 5.95999 7.24065 5.88666 7.24732C4.29999 7.19398 3.03999 5.89398 3.03999 4.29398C3.03999 2.66065 4.35999 1.33398 5.99999 1.33398C7.63333 1.33398 8.95999 2.66065 8.95999 4.29398C8.95333 5.89398 7.69333 7.19398 6.10666 7.24732Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.94 2.66602C12.2333 2.66602 13.2733 3.71268 13.2733 4.99935C13.2733 6.25935 12.2733 7.28602 11.0267 7.33268C10.9733 7.32602 10.9133 7.32602 10.8533 7.33268" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.77334 9.70602C1.16001 10.786 1.16001 12.546 2.77334 13.6193C4.60667 14.846 7.61334 14.846 9.44667 13.6193C11.06 12.5393 11.06 10.7793 9.44667 9.70602C7.62001 8.48602 4.61334 8.48602 2.77334 9.70602Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.2267 13.334C12.7067 13.234 13.16 13.0407 13.5333 12.754C14.5733 11.974 14.5733 10.6873 13.5333 9.90732C13.1667 9.62732 12.72 9.44065 12.2467 9.33398" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default Audience
